import { ChainId, Currency, CurrencyAmount, Token, Trade, } from "@caga-cryptos/cagaswap-v2-sdk";
import React, { useCallback, useContext, useEffect, useMemo, useState, CSSProperties, } from "react";
import ERC20_INTERFACE from '../../constants/abis/erc20.json';
import { Text } from "rebass";
import { ThemeContext } from "styled-components";
import { ButtonError, ButtonPrimary, } from "../../components/Button";
import Card, { GreyCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import ConfirmSwapModal from "../../components/Swap/ConfirmSwapModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import { SwapPoolTabs } from "../../components/NavigationTabs";
import { RowBetween } from "../../components/Row";
import AdvancedSwapDetailsDropdown from "../../components/Swap/AdvancedSwapDetailsDropdown";
import SepolioAdvancedSwapDetailsDropdown from "../../components/Swap/SepoliaAdvancedSwapDetailsDropdown";
import confirmPriceImpactWithoutFee from "../../components/Swap/confirmPriceImpactWithoutFee";
import { BottomGrouping, Dots, SwapCallbackError, Wrapper, } from "../../components/Swap/styleds";
import TradePrice from "../../components/Swap/TradePrice";
import TokenWarningModal from "../../components/TokenWarningModal";
import { INITIAL_ALLOWED_SLIPPAGE, } from "../../constants";
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import { useCurrency } from "../../hooks/Tokens";
import { ApprovalState, useApproveCallbackFromTrade, } from "../../hooks/useApproveCallback";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { useToggleSettingsMenu } from "../../state/application/hooks";
import { Field } from "../../state/swap/actions";
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState, } from "../../state/swap/hooks";
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from "../../state/user/hooks";
import { TYPE } from "../../theme";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { computeTradePriceBreakdown, warningSeverity, } from "../../utils/prices";
import AppBody from "../AppBody";
import InformationSection from "../../components/InformationSection";
import { ClickableText } from "../Pool/styleds";
import { addTokenToMetamask } from "../../utils/addTokenToMetamask";
import tokenList from "@caga-cryptos/default-token-list";
import { useIsDarkMode } from "../../state/user/hooks";
import { ethers } from "ethers";
import { MainContainer, ContentContainer } from "../LayoutStyles";
import SideMenu from "../../components/SideMenu";
import { useWeb3Modal } from '@web3modal/ethers5/react'
import ModalTnx from "../../components/ModalTnx";
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal';
import EthIcon from "../../assets/images/eth.svg";
import { logEvent } from '../../ga4'; // Import GA4 event logging

import {
  Spinner,
  SmallSpinner,
  BalanceDisplay,
  CurrencyDisplay,
  StyledTokenName,
  Section,
  Column, Row,
  Info,
  BalanceValue,
  Value,
  Dropdown, NetworkIcon, DropdownOption,
  StyledDropDown,
  ButtonContainer, StyledCurrencyIcon
} from './styled';



interface TokenAddresses {
  [key: string]: string;
}
const TOKEN_ADDRESSES: TokenAddresses = {
  USDC: "0xa4eE9C98643403cF6555728272129c721EF4c266",
  USDT: "0x3637925eE8B837f85c7309e4b291Ca56A40457a4",
  DOGE: "0xE1eC108ba9fB9B8A691848d9C5A34aA5fBaA6e23",
  WBTC: "0x835EF3b3D6fB94B98bf0A3F5390668e4B83731c5",
  CTT: "0x2514c246226EEE6EF9F3aB017Be2c01FAa3312dD",
};
const CONTRACT_ADDRESS = "0x7F1b29A5b052260b669Cb8E39139e007c03F2dEc";
const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
    ],
    name: "charge",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

interface DownArrowIconProps {
  style?: CSSProperties;
  strokeColor?: string;
  onClick?: () => void; // Adding onClick as an optional prop
}

const DownArrowIcon: React.FC<DownArrowIconProps> = ({
  strokeColor,
  style,
  onClick,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30' // Customizable width
    height='30' // Customizable height
    viewBox='0 0 24 24'
    fill='none'
    stroke={strokeColor || "#C3C5CB"}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    style={style}
    onClick={onClick}
  >
    {/* SVG with arrows pointing in opposite directions */}
    <path d='M5 8l7-6 7 6' /> {/* Top arrow pointing right */}
    <path d='M19 16l-7 6-7-6' /> {/* Bottom arrow pointing left */}
  </svg>
);

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch();
  const { open } = useWeb3Modal()
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null);

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(
    false
  );
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const { account, library } = useWeb3ReactContext();
  const theme = useContext(ThemeContext);

  // for expert mode
  const toggleSettings = useToggleSettingsMenu();
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = v2Trade;

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount,
    }
    : {
      [Field.INPUT]:
        independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]:
        independentField === Field.OUTPUT
          ? parsedAmount
          : trade?.outputAmount,
    };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
      //handleConfirmVisibility();
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };



  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const isBuying =
    currencies[Field.INPUT]?.symbol === "CAGA" &&
    ["USDT", "CTT", "WBTC", "DOGE", "USDC"].includes(
      currencies[Field.OUTPUT]?.symbol as string
    );

  const handleBuy = async () => {

    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";
    const baseUrl = isLocalhost ? "" : "https://www.ankara-cagacrypto.com";

    if (!account || !library || !typedValue || isNaN(parseFloat(typedValue))) {
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm: undefined,
        showConfirm: false,
        swapErrorMessage:
          "Please connect your wallet and enter a valid amount.",
        txHash: undefined,
      });
      return;
    }

    setShowModal(true);
    setModalStatus('sign');


    setSwapState((prevState) => ({
      ...prevState,
      swapErrorMessage: "Please wait, processing...",
    }));

    let resp;
    const selectedTokenAddress =
    TOKEN_ADDRESSES[currencies[Field.OUTPUT]?.symbol || "CTT"];

    try {
      const signer = library.getSigner(account);
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI,
        signer
      );

      const txResponse = await contract.charge(
        ethers.utils.parseUnits(typedValue, "ether"), // Convert to BigNumber for contract parameter
        { value: ethers.utils.parseEther(typedValue) } // Specify Ether to send with the transaction
      );

      logEvent('Transaction', 'Buy Initiated',selectedTokenAddress);
      const receipt = await txResponse.wait(); // Wait for the transaction to be mined
      // //console.log("Transaction approved, processing...");
      setModalStatus('doing');
      // Optionally add further logic post-transaction
 
    

      const payload = {
        wallet: account,
        token: selectedTokenAddress,
        value: ethers.utils.parseEther(typedValue).toString(),
      };

      // //console.log("Payload for the API:", payload);

      const response = await fetch(`${baseUrl}/dex/api/v1/transaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();


      resp = data;

      console.log(data)

      if (data.statusMsg === "200 OK") {
        let url = data.body.FinalTxToUser
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm: undefined,
          showConfirm: false,
          swapErrorMessage: `Transaction successful! View at ${data.body.FinalTxToUser}`,
          txHash: receipt.transactionHash,
        });
        setTimeout(() => {
          setModalStatus('done-pass');
          setTransactionUrl(data.body.FinalTxToUser);

          setTimeout(() => {
            fetchOutputBalance();
          }, 3000);
        // Log successful transaction
        logEvent('Transaction', 'Buy Success', selectedTokenAddress);

        }, 2000);
      } else {
        throw new Error("API response indicated failure.");
      }
    } catch (error) {
      console.error("Purchase failed: ", error);
      setModalStatus('done-fail');

      let errorMessage = "Transaction failed. ";
      if (typeof error === "string") {
        errorMessage += resp.statusMsg; // Error is a string
      } else if (error instanceof Error) {
        errorMessage += error.message; // Error is an Error object
      } else {
        errorMessage += "An unexpected error occurred"; // Fallback error message
      }
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm: undefined,
        showConfirm: false,
        swapErrorMessage: errorMessage,
        txHash: undefined,
      });
          // Log failed transaction
    logEvent('Transaction', 'Buy Failed',selectedTokenAddress);

    } finally {
      setTimeout(() => setShowModal(false), 20000);
    }
  };

  const isSpecialPair = useCallback(() => {
    const inputSymbol = currencies[Field.INPUT]?.symbol;
    const outputSymbol = currencies[Field.OUTPUT]?.symbol;
    const selectedSymbol = selectedCurrency?.symbol;

    return inputSymbol === 'CAGA' &&
      (['WBTC', 'USDT', 'USDC', 'DOGE', 'CTT'].includes(outputSymbol ?? '') ||
        outputSymbol === undefined && ['WBTC', 'USDT', 'USDC', 'DOGE', 'CTT'].includes(selectedSymbol ?? ''));

  }, [currencies, selectedCurrency]);

  const handleSwap = useCallback(() => {
    logEvent('Transaction', 'Swap Initiated', `${currencies[Field.INPUT]?.symbol} to ${currencies[Field.OUTPUT]?.symbol}`);
    if (selectedNetwork === 'sepolia' && isSpecialPair()) {
      handleBuy();
    } else {
      if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
        return;
      }

      if (!swapCallback) {
        return;
      }
      setShowModal(true);
      setModalStatus('sign');

      setSwapState({
        attemptingTxn: true,
        tradeToConfirm,
        showConfirm,
        swapErrorMessage: undefined,
        txHash: undefined,
      });

      swapCallback()
        .then((hash) => {
          console.log(hash)
          setShowModal(false);
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: undefined,
            txHash: hash,
          });

          logEvent('Transaction', 'Swap Success', `${currencies[Field.INPUT]?.symbol} to ${currencies[Field.OUTPUT]?.symbol}`);
        })
        .catch((error) => {
          let modalStatus;
          if (error.message.includes("Amount")) {
            modalStatus = 'done-cnt-2'; // Specific status for "Incorrect amount of CAGA sent"
          } else if (error.message.includes("exceeded")) {
            modalStatus = 'done-cnt'; // Specific status for "Cooldown"
          }else if (isCAGAtoCNT ){
            modalStatus = 'cnt-invalid';
          } else {
            modalStatus = 'done-fail'; // General status for other errors
          }

          console.log(isSpecialPair)

          setModalStatus(modalStatus);
          console.log(error)
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: error.message,
            txHash: undefined,
          });

          logEvent('Transaction', 'Swap Failed', `${currencies[Field.INPUT]?.symbol} to ${currencies[Field.OUTPUT]?.symbol}`);
        });
    }
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback]);


  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    });
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      setSelectedCurrency(outputCurrency);
    },
    [onCurrencySelection]
  );

  const handleAddTokenToMM = useCallback((address, decimals, symbol, name) => {
    const { ethereum } = window;
    const selectedToken = new Token(
      ChainId.CAGA,
      address,
      decimals,
      symbol,
      name
    );
    addTokenToMetamask(ethereum, selectedToken);
  }, []);

  const tokenOutputSelected = tokenList.tokens.find(
    (t) => t.symbol === currencies[Field.OUTPUT]?.symbol
  );

  const isDarkMode = useIsDarkMode();
  const borderStyle = isDarkMode ? "2px solid white" : "4px solid white";

  const isSwapSupported = () => {
    const inputSymbol = currencies[Field.INPUT]?.symbol;
    const outputSymbol = currencies[Field.OUTPUT]?.symbol;

    // Check if the current user's wallet address has special privileges
    const privilegedAddress = "0x8167E23F7e36432458F5fCD052071530a04dafb2";
    if (account === privilegedAddress) {
      return true; // No restrictions for this specific address
    }


    if (inputSymbol === 'CNT' || outputSymbol === 'CNT') {
      return false;
    }

    return true;
  };


  const isCAGAtoCNT =  currencies[Field.INPUT]?.symbol ==="CAGA" && currencies[Field.OUTPUT]?.symbol ==="CNT";

  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState<'doing' | 'done-pass' | 'done-fail' | 'done-cnt' | 'done-cnt-2' | 'cnt-invalid'| 'sign'>('doing');
  const [transactionUrl, setTransactionUrl] = useState('');
  const outputSymbol = currencies[Field.OUTPUT]?.symbol;




  const [amount, setAmount] = useState('');
  const [selectedOutputCurrency, setSelectedOutputCurrency] = useState(useCurrency(''));
  const isSelected = selectedOutputCurrency !== null;

  interface ExtendedCurrency extends Currency {
    logoURI?: string;
    address?: string;

  }
  const [transactionStatus, setTransactionStatus] = useState({
    message: '',
    link: '',
    linkText: '',
    isSuccess: false
  });

  const [cttBalance, setCttBalance] = useState('');
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);



  const [showBalanceDisplay, setShowBalanceDisplay] = useState(false);

  const specialTokens = ['USDT', 'USDC', 'WBTC', 'DOGE', 'CTT'];

  useEffect(() => {
    const isOutputTokenSpecial = specialTokens.includes(currencies[Field.OUTPUT]?.symbol ?? '');
    const isSelectedCurrencySpecial = selectedCurrency ? specialTokens.includes(selectedCurrency.symbol ?? '') : false;
    const isFromCAGA = currencies[Field.INPUT]?.symbol === 'CAGA';

    // Show BalanceDisplay if "From" is CAGA and either output token or selected currency is special
    setShowBalanceDisplay(isFromCAGA && (isOutputTokenSpecial || isSelectedCurrencySpecial));

  }, [currencies[Field.INPUT]?.symbol, currencies[Field.OUTPUT]?.symbol, selectedCurrency?.symbol]);


  const [lastNonCAGACurrency, setLastNonCAGACurrency] = useState<Currency | null>(null);

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);


  const handleCurrencySelect = useCallback((currency: Currency) => {
    // Check if the selected currency is 'CAGA'

    const symbol = ((currency as ExtendedCurrency)?.symbol);
    if (symbol === 'CAGA' && lastNonCAGACurrency) {
      // Revert to the last non-CAGA currency

      setSelectedCurrency(lastNonCAGACurrency);
    } else {

      // Update the selected currency
      setSelectedCurrency(currency);
      // Update last non-CAGA currency if the current one isn't 'CAGA'
      setLastNonCAGACurrency(symbol !== 'CAGA' ? currency : lastNonCAGACurrency);
    }
    // Trigger output field currency selection and close modal
    onCurrencySelection(Field.OUTPUT, currency);
    handleCloseModal();
  }, [handleCloseModal]);

  const fetchOutputBalance = useCallback(async () => {
    if (!account || !library || !selectedCurrency) return;  // Check if selectedCurrency is null

    // Ensure we have a valid address before proceeding
    const tokenAddress = (selectedCurrency as ExtendedCurrency)?.address || '';
    if (!tokenAddress) {
      //console.log("No valid token address provided.");
      return;
    }

    setLoadingBalance(true);

    //console.log("Fetching balance for address:", tokenAddress);
    try {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20_INTERFACE, library);
      const balance = await tokenContract.balanceOf(account);
      // Assuming the token decimals might not always be 18, handle dynamically if possible
      const decimals = 18; // This should ideally be fetched from the token contract or cached data
      const balanceFormatted = parseFloat(ethers.utils.formatUnits(balance, decimals)).toFixed(4);
      setCttBalance(balanceFormatted);
      setLoadingBalance(false);
    } catch (error) {
      setLoadingBalance(false);
    }

  }, [account, library, selectedCurrency]);

  useEffect(() => {
    fetchOutputBalance();
  }, [fetchOutputBalance]);


  const [selectedNetwork, setSelectedNetwork] = useState('sepolia');
  const handleNetworkChange = useCallback((network) => {
    //console.log("Selected network:", network);
    setSelectedNetwork(network);
    // Update state or perform actions based on the selected network
  }, []);


  const [showSepoliaDetails, setShowSepoliaDetails] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  // Assuming isSpecialPair and selectedNetwork are correctly defined and available
  const handleConfirmVisibility = useCallback(() => {

    if (isSpecialPair() && selectedNetwork === 'sepolia') {
      setShowConfirmButton(true);
      setShowSepoliaDetails(false); // Hide details when conditions are initially met
    } else {
      setShowConfirmButton(false);
      setShowSepoliaDetails(false); // Hide both if conditions are not met
    }


  }, [selectedNetwork, currencies[Field.OUTPUT]?.symbol, selectedCurrency, currencies[Field.INPUT]?.symbol]); // Ensure dependencies are correctly listed

  useEffect(() => {
    handleConfirmVisibility();
  }, [handleConfirmVisibility]); // This will react to changes in handleConfirmVisibility

  const handleConfirmClick = useCallback(() => {
    //console.log("Confirm click activated.");
    setShowSepoliaDetails(true); // Show details
    setShowConfirmButton(false); // Hide confirm button to make way for "Swap" button
  }, []);
  //const buyTokenAddress = currencies[Field.OUTPUT] instanceof Token ? currencies[Field.OUTPUT].address : '';


  const buyTokenAddress = TOKEN_ADDRESSES[currencies[Field.OUTPUT]?.symbol || selectedCurrency?.symbol || ''];

  function formatLargeNumbers(balance: any) {
    // Check if the balance is not provided or is not a number
    if (!balance || isNaN(parseFloat(balance))) return ' -';

    const number = parseFloat(balance);

    // Use standard formatting for numbers less than 1 million
    if (number < 1e6) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        useGrouping: false  // Ensure no grouping for numbers below 1 million
      }).format(number);
    }

    // Use compact notation for numbers greater than or equal to 1 million but less than a certain threshold
    if (number < 1e21) {
      return new Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2,
        useGrouping: false  // Ensure no grouping for compact notation
      }).format(number);
    }

    // Handle very large numbers using scientific notation without groupings
    return number.toExponential(2);
  }
  return (
    <>
      {showModal && (
        <ModalTnx
          status={modalStatus}
          title={modalStatus === 'doing' ? "Processing Your Transaction" :
            modalStatus === 'done-pass' ? "Transaction Completed" : "Transaction Failed"}
          subtitle={modalStatus === 'doing' ? "Please wait while we confirm your transaction on the blockchain." :
            modalStatus === 'done-pass' ? "Your transaction has been confirmed." :
              "There was an error processing your transaction. Please try again."}
          onDismiss={() => setShowModal(false)}
          url={transactionUrl}
        />
      )}
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      {/*
      {tokenOutputSelected && (
        <ButtonImagePlus
          onClick={() =>
            handleAddTokenToMM(
              tokenOutputSelected.address,
              tokenOutputSelected.decimals,
              tokenOutputSelected.symbol,
              tokenOutputSelected.name
            )
          }
          style={{
            width: "auto",
            position: "absolute",
            marginTop: "-20px",
            marginRight: isMobile ? "" : "-200px",
            whiteSpace: "nowrap",
          }}
        >
          Add {tokenOutputSelected.symbol} to MetaMask
        </ButtonImagePlus>
      )}
    */}
      <MainContainer>
        <SideMenu />
        <ContentContainer>
          <AppBody>
            <SwapPoolTabs active={"swap"} />
            <Wrapper id='swap-page'>
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
              />

              <AutoColumn gap={"md"} displayType={"block"}>
                <CurrencyInputPanel
                  label={
                    independentField === Field.OUTPUT && !showWrap
                      ? "From (estimated)"
                      : "From"
                  }
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id='swap-currency-input'
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "5px 1rem",
                    position: "relative",
                  }}
                >
                  <DownArrowIcon
                    strokeColor={theme.text1}
                    style={{
                      zIndex: "99",
                      width: "30px",
                      height: "30px",
                      backgroundColor: theme.fieldBg1,
                      border: borderStyle,
                      borderRadius: "40px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={() => {
                      setApprovalSubmitted(false); // Reset UI state
                      onSwitchTokens(); // Switch tokens function
                    }}
                  />
                </div>
                {!showBalanceDisplay && (
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.OUTPUT]}
                    onUserInput={handleTypeOutput}
                    label={
                      independentField === Field.INPUT && !showWrap
                        ? "To (estimated)"
                        : "To"
                    }
                    showMaxButton={false}
                    currency={currencies[Field.OUTPUT]}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    showCommonBases={false}
                    id='swap-currency-output'
                  />
                )}

                {showBalanceDisplay && (
                  <BalanceDisplay>
                    <Section>
                      {/* Left column for "You Receive" and its value */}
                      <Column>
                        <Info>Swap In</Info>
                        <Row>
                          {/* Conditional rendering based on selectedNetwork */}
                          <NetworkIcon
                            src={selectedNetwork === 'sepolia' ? EthIcon : "https://www.cagacrypto.com/tokens/caga.svg"}
                            alt="Network Icon"
                          />
                          <Dropdown value={selectedNetwork} onChange={(e) => handleNetworkChange(e.target.value)}>
                            <DropdownOption value="sepolia">Sepolia</DropdownOption>
                            <DropdownOption value="caga">CAGA</DropdownOption>
                          </Dropdown>
                        </Row>
                      </Column>

                      {/* Right column for "Balance", ETH icon, and name, with adjustments for inline balance display */}
                      <Column>
                        {/* Using a flex container to keep "Balance:" and the value inline */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                          <Info>Balance:&nbsp;</Info>
                          <Value>{loadingBalance ? <SmallSpinner /> : formatLargeNumbers(cttBalance) || '-'}</Value>
                        </div>
                        <CurrencyDisplay selected={true}> {/* Assume selected state or dynamic based on your logic */}
                          <ButtonContainer onClick={handleOpenModal} selected={isSelected}>
                            <StyledCurrencyIcon>
                              <img src={(selectedCurrency as ExtendedCurrency)?.logoURI || 'https://www.cagacrypto.com/tokens/caga.svg'} alt="Currency" />
                            </StyledCurrencyIcon>
                            <StyledTokenName>{selectedCurrency?.symbol || 'CTT'}</StyledTokenName>
                            {/* Assuming you have a component or method to render the dropdown icon */}
                            <StyledDropDown selected={isSelected} />
                          </ButtonContainer>

                        </CurrencyDisplay>
                      </Column>
                    </Section>
                  </BalanceDisplay>
                )}
                <CurrencySearchModal
                  isOpen={modalOpen}
                  onDismiss={handleCloseModal}
                  onCurrencySelect={handleCurrencySelect}
                  selectedCurrency={selectedCurrency}

                />
                {showWrap || showBalanceDisplay ? null : (
                  <Card
                    padding={".25rem .75rem 0 .75rem"}
                    borderRadius={"20px"}
                    marginTop={"10px"}
                  >
                    <AutoColumn gap='4px'>
                      <RowBetween align='center'>
                        <Text
                          fontWeight={500}
                          fontSize={14}
                          color={theme.text2}
                        >
                          Price
                        </Text>
                        <TradePrice
                          inputCurrency={currencies[Field.INPUT]}
                          outputCurrency={currencies[Field.OUTPUT]}
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      </RowBetween>

                      {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                        <RowBetween align='center'>
                          <ClickableText
                            fontWeight={500}
                            fontSize={14}
                            color={theme.text2}
                            onClick={toggleSettings}
                          >
                            Slippage Tolerance
                          </ClickableText>
                          <ClickableText
                            fontWeight={500}
                            fontSize={14}
                            color={theme.text2}
                            onClick={toggleSettings}
                          >
                            {allowedSlippage / 100}%
                          </ClickableText>
                        </RowBetween>
                      )}
                    </AutoColumn>
                  </Card>
                )}
              </AutoColumn>
              <BottomGrouping>
                {!account ? (
                  <ButtonPrimary onClick={() => open()}>
                    Connect Wallet
                  </ButtonPrimary>
                ) : !isSwapSupported() ? (
                  <GreyCard style={{ textAlign: "center" }}>
                    <TYPE.main mb='4px'>
                      Swap not supported for selected token combination.
                    </TYPE.main>
                  </GreyCard>
                ) : showWrap ? (
                  <ButtonPrimary
                    disabled={Boolean(wrapInputError) || attemptingTxn}
                    onClick={onWrap}
                  >
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP
                        ? "Wrap"
                        : wrapType === WrapType.UNWRAP
                          ? "Unwrap"
                          : null)}
                  </ButtonPrimary>
                ) : showConfirmButton ? (
                  <ButtonPrimary onClick={handleConfirmClick}>
                    Confirm
                  </ButtonPrimary>
                ) : (
                  <>
                    {showApproveFlow ? (
                      <RowBetween>
                        <ButtonPrimary
                          onClick={approveCallback}
                          disabled={
                            approval !== ApprovalState.NOT_APPROVED ||
                            approvalSubmitted
                          }
                          width='48%'
                        >
                          {approval === ApprovalState.PENDING ? (
                            <Dots>Approving</Dots>
                          ) : approvalSubmitted &&
                            approval === ApprovalState.APPROVED ? (
                            "Approved"
                          ) : (
                            "Approve " + currencies[Field.INPUT]?.symbol
                          )}
                        </ButtonPrimary>


                        <ButtonError
                          onClick={handleSwap} // Now always use handleSwap which will internally decide what to do
                          width='48%'
                          id='swap-button'
                          disabled={
                            !isValid ||
                            approval !== ApprovalState.APPROVED ||
                            (priceImpactSeverity > 3 && !isExpertMode)
                          }
                          error={
                            isValid && priceImpactSeverity > 2
                          }
                        >
                          <Text fontSize={16} fontWeight={500}>
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact High`
                              : `Swap`}
                          </Text>
                        </ButtonError>
                      </RowBetween>
                    ) : (
                      <ButtonError
                      onClick={handleSwap}
                      id='swap-button'
                      disabled={
                        !isValid ||
                        (priceImpactSeverity > 3 && !isExpertMode) ||
                        !!swapCallbackError ||
                        !isSwapSupported()
                      }
                      error={
                        isValid &&
                        priceImpactSeverity > 2 &&
                        !swapCallbackError
                      }
                    >
                      <Text fontSize={20} fontWeight={500}>
                        {
                          swapInputError ? (
                            swapInputError  // If there is a specific input error, show this first
                          ) : (!isValid || !isSwapSupported() || !!swapCallbackError ? (
                            <Spinner />  // Show spinner when disabled for validations not related to price impact
                          ) : priceImpactSeverity > 3 && !isExpertMode ? (
                            "Price Impact Too High"  // Show this only if price impact is too high when not in expert mode
                          ) : (
                            "Swap"  // Default action text when no other conditions apply
                          ))
                        }
                      </Text>
                    </ButtonError>
                    )}
                  </>
                )}
                {isExpertMode && swapErrorMessage ? (
                  <SwapCallbackError error={swapErrorMessage} />
                ) : null}
              </BottomGrouping>
            </Wrapper>
          </AppBody>

          {
            showSepoliaDetails ? (

              <SepolioAdvancedSwapDetailsDropdown
                show={true}
                buyToken={buyTokenAddress}
                sellAmount={typedValue}
              />

            ) : isSpecialPair() && selectedNetwork === 'sepolia' ? (<div style={{ marginBottom: '36px' }}></div>) : (
              <AdvancedSwapDetailsDropdown trade={trade} />
            )
          }
          <InformationSection trade={trade !== undefined} />
        </ContentContainer>
      </MainContainer>
    </>
  );
}
