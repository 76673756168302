import { useState, useEffect } from 'react';
import { namehash } from 'ethers/lib/utils';
import isZero from '../utils/isZero';
import { useENSRegistrarContract, useENSResolverContract } from './useContract';
import useDebounce from './useDebounce';

interface ENSAddressReturn {
  loading: boolean;
  address: string | null;
}

export default function useENSAddress(ensName?: string | null): ENSAddressReturn {
  const debouncedName = useDebounce(ensName, 200);
  const [address, setAddress] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const registrarContract = useENSRegistrarContract(false);
  const [resolverAddress, setResolverAddress] = useState<string | undefined>(undefined);

  // Fetch Resolver Address
  useEffect(() => {
    if (!debouncedName) {
      setResolverAddress(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);
    const ensNode = namehash(debouncedName);
    registrarContract?.resolver(ensNode).then((addr: string) => {
      if (!isZero(addr)) {
        setResolverAddress(addr);
      } else {
        setResolverAddress(undefined);
        setAddress(null);
        setLoading(false);
      }
    }).catch((error: Error) => {
      console.error('Failed to fetch resolver address:', error);
      setResolverAddress(undefined);
      setLoading(false);
    });
  }, [debouncedName, registrarContract]);

  const resolverContract = useENSResolverContract(resolverAddress, false);

  // Fetch ENS Address using Resolver Contract
  useEffect(() => {
    if (!resolverAddress || isZero(resolverAddress) || !debouncedName) {
      setAddress(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    const ensNode = namehash(debouncedName);
    resolverContract?.addr(ensNode).then((ensAddr: string) => {
      setAddress(ensAddr);
      setLoading(false);
    }).catch((error: Error) => {
      console.error('Failed to fetch ENS address:', error);
      setAddress(null);
      setLoading(false);
    });
  }, [resolverAddress, resolverContract, debouncedName]);

  return { address, loading };
}
