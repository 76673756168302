// src/contexts/Web3Context.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { ActiveWeb3ReactContext, useActiveWeb3React } from '../hooks';

export const Web3ReactContext = createContext<ActiveWeb3ReactContext | undefined>(undefined);

export const Web3ReactProvider = ({ children }: { children: ReactNode }) => {
    const web3React = useActiveWeb3React();
    return (
        <Web3ReactContext.Provider value={web3React}>
            {children}
        </Web3ReactContext.Provider>
    );
};

export const useWeb3ReactContext = () => {
    const context = useContext(Web3ReactContext);
    if (!context) {
        throw new Error('useWeb3ReactContext must be used within a Web3ReactProvider');
    }
    return context;
};
