import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TableContainer, StyledTable, Th, Td, PoolName, TokenLogoContainer, TokenLogo, SpinnerWrapper, SpinnerIcon } from './styled'; // Adjusted import
import caga from '../../assets/tokens/caga.png';
import cnt from '../../assets/tokens/cnt.png';
import doge from '../../assets/tokens/doge.svg';
import usdc from '../../assets/tokens/usdc.svg';
import usdt from '../../assets/tokens/usdt.svg';
import wbtc from '../../assets/tokens/wbtc.svg';

const tokenLogos: { [key: string]: string } = {
  CAGA: caga,
  CTT: caga, // CTT uses CAGA logo
  CNT: cnt,
  DOGE: doge,
  USDC: usdc,
  USDT: usdt,
  WBTC: wbtc,
  wCAGA: caga, // Map wCAGA to CAGA logo
};

const validTokens = ['CAGA', 'CTT', 'CNT', 'DOGE', 'USDT', 'USDC', 'WBTC'];

const formatTimeAgo = (date: Date) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) return `${interval}y ago`;
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval}mo ago`;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval}d ago`;
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval}h ago`;
  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval}m ago`;
  return `${Math.floor(seconds)}s ago`;
};

const formatAmount = (amount: number) => {
  if (amount < 0.001) return '<0.001';
  if (amount < 1) return amount.toFixed(3);
  if (amount >= 1e9) return `${(amount / 1e9).toFixed(2)}B`;
  if (amount >= 1e6) return `${(amount / 1e6).toFixed(2)}M`;
  if (amount >= 1e3) return `${(amount / 1e3).toFixed(2)}K`;
  return amount.toFixed(2);
};

const formatWallet = (wallet: string) => {
  return `${wallet.slice(0, 6)}...${wallet.slice(-4)}`;
};

interface Transaction {
  transaction_hash: string;
  timestamp: Date;
  type: string;
  tokenAAmount: number;
  tokenBAmount: number;
  wallet: string;
  tokenA: string;
  tokenB: string;
}

const TransactionsList: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://dex-info-testnet.cagacrypto.com/api/latesttransactions', {
          params: {
            limit: 10,
            tokens: validTokens.join(','),
          },
        });
        const data = response.data.map((tx: Transaction) => {
          if (tx.tokenA === 'wCAGA') tx.tokenA = 'CAGA';
          if (tx.tokenB === 'wCAGA') tx.tokenB = 'CAGA';
          return tx;
        });
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <Th><span style={{ fontWeight: 'normal', marginRight: '3px' }}>↓</span>Time</Th>
            <Th style={{ textAlign: 'left' }}>Type</Th>
            <Th>Token A Amount</Th>
            <Th>Token B Amount</Th>
            <Th>Wallet</Th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={5}>
                <SpinnerWrapper>
                  <SpinnerIcon />
                </SpinnerWrapper>
              </Td>
            </tr>
          ) : (
            transactions
              .filter(tx => validTokens.includes(tx.tokenA.toUpperCase()) && validTokens.includes(tx.tokenB.toUpperCase()))
              .map((tx) => (
                <tr key={tx.transaction_hash}>
                  <Td style={{ fontWeight: 600 }}>{formatTimeAgo(new Date(tx.timestamp))}</Td>
                  <PoolName>
                    <span style={{ fontWeight: 'normal', marginRight: '3px' }}>Swap</span>
                    <TokenLogo src={tokenLogos[tx.tokenA.toUpperCase()]} alt={tx.tokenA} width="20" height="20" />
                    <span style={{ marginLeft: '3px', fontWeight: 600 }}>{tx.tokenA.toUpperCase()}</span>
                    <span style={{ fontWeight: 'normal', margin: '0 3px' }}>for</span>
                    <TokenLogo src={tokenLogos[tx.tokenB.toUpperCase()]} alt={tx.tokenB} width="20" height="20" />
                    <span style={{ marginLeft: '3px', fontWeight: 600 }}>{tx.tokenB.toUpperCase()}</span>
                  </PoolName>
                  <Td style={{ fontWeight: 600 }}>
                    <span style={{ marginRight: '12px' }}>
                      {formatAmount(tx.tokenAAmount)}{' '}
                    </span>
                    <TokenLogo src={tokenLogos[tx.tokenA.toUpperCase()]} alt={tx.tokenA} width="20" height="20" />{' '}
                    {tx.tokenA.toUpperCase()}
                  </Td>
                  <Td style={{ fontWeight: 600 }}>
                    <span style={{ marginRight: '12px' }}>
                      {formatAmount(tx.tokenBAmount)}{' '}
                    </span>
                    <TokenLogo src={tokenLogos[tx.tokenB.toUpperCase()]} alt={tx.tokenB} width="20" height="20" />{' '}
                    {tx.tokenB.toUpperCase()}
                  </Td>
                  <Td style={{ fontWeight: 600 }}>{formatWallet(tx.wallet)}</Td>
                </tr>
              ))
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default TransactionsList;