import ReactGA from 'react-ga4';

const measurementId = 'G-ZMZLDD4CLV'; // Replace with your GA4 measurement ID

export const initGA = () => {
    ReactGA.initialize(measurementId);
  };
  
  export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  };
  
  export const logEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };
  
  export const logException = (description: string, fatal: boolean = false) => {
    ReactGA.event({
      category: 'Exception',
      action: description,
      label: fatal ? 'Fatal' : 'Non-Fatal',
    });
  };
