import { useState, useEffect, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { useWeb3ReactContext } from '../contexts/Web3Context';
import { useSocksController } from './useContract';
import { JSBI } from '@caga-cryptos/cagaswap-v2-sdk';

export default function useSocksBalance(): JSBI | undefined {
  const { account } = useWeb3ReactContext();
  const socksContract = useSocksController();
  const [balance, setBalance] = useState<JSBI | undefined>(undefined);

  useEffect(() => {
    if (account && socksContract) {
      const fetchBalance = async () => {
        try {
          const balanceValue: BigNumber = await socksContract.balanceOf(account);
          setBalance(JSBI.BigInt(balanceValue.toString()));
        } catch (error) {
          console.error('Failed to fetch balance:', error);
          setBalance(undefined);
        }
      };

      fetchBalance();
    } else {
      setBalance(undefined);
    }
  }, [account, socksContract]);

  return balance;
}

export function useHasSocks(): boolean | undefined {
  const balance = useSocksBalance();
  return useMemo(() => !!balance && JSBI.greaterThan(balance, JSBI.BigInt(0)), [balance]);
}
