import styled, { keyframes} from 'styled-components';
import { TYPE } from '../../theme';
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1); // Light grey border for the "unfilled" part of the spinner
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #26a697; // Your chosen spinner color
  animation: ${spin} 1s ease infinite;
`;

export  const SmallSpinner = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid #26a697; // Spinner color
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
  margin-left: 10px;
`;

export const BalanceDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg2};
  border-radius: 12px;
  margin-top: 1rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled(TYPE.body)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px !important;
  color: ${({ theme }) => theme.text1};
`;

export const BalanceValue = styled(TYPE.body)`
  font-size: 24px !important;
  margin-top: 10px !important;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;

export const Value = styled(TYPE.body)`
  font-size: 14px !important; 
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;

export const CurrencyIconContainer = styled.div`
  display: flex;

  align-items: center; // This ensures vertical alignment is centered
  justify-content: center;
  margin-right: 10px; // Adjust the spacing between the icon and the text as needed
`;


export const CurrencyDisplay = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => selected ? theme.bg1 : theme.primary1};
  color: ${({ selected, theme }) => selected ? theme.text1 : theme.white};
  border-radius: 12px;
  box-shadow: ${({ selected }) => selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)'};
  padding: 0 0.5rem;
`;

export const StyledTokenName = styled.span<{ active?: boolean }>`
  display: flex;
  align-items: center; // Ensures vertical alignment with the icon
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1}; // Adjust as needed
`;

export const ButtonContainer = styled.button<{ selected: boolean }>`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
`;

export const StyledCurrencyIcon = styled.div`
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  margin-right: 10px; // Provides spacing between the icon and the text

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`;