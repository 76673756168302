import { Currency, ETHER, Token } from '@caga-cryptos/cagaswap-v2-sdk'
import { useMemo,useState,useEffect} from 'react'
import { useSelectedTokenList } from '../state/lists/hooks'
import { useUserAddedTokens } from '../state/user/hooks'
import { isAddress,parseStringOrBytes32 } from '../utils'

import { useWeb3ReactContext } from '../contexts/Web3Context';
import { useBytes32TokenContract, useTokenContract } from './useContract'


export function useAllTokens(): { [address: string]: Token } {
  const { chainId } = useWeb3ReactContext();
  const userAddedTokens = useUserAddedTokens()
  const allTokens = useSelectedTokenList()

  return useMemo(() => {
    if (!chainId) return {}
    return (
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: Token }>(
          (tokenMap, token) => {
            tokenMap[token.address] = token
            return tokenMap
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          { ...allTokens[chainId] }
        )
    )
  }, [chainId, userAddedTokens, allTokens])
}

export function useToken(tokenAddress?: string): Token | undefined | null {
  const { chainId } = useWeb3ReactContext();
  const tokens = useAllTokens();
  const address = isAddress(tokenAddress) || undefined; 
  const [tokenDetails, setTokenDetails] = useState<Token | undefined | null>(null);

  const tokenContract = useTokenContract(address);
  const tokenContractBytes32 = useBytes32TokenContract(address);


  useEffect(() => {
    if (!chainId || !address) {
      setTokenDetails(null);
      return;
    }

    const existingToken = tokens[address];
    if (existingToken) {
      setTokenDetails(existingToken);
      return;
    }

    const fetchTokenDetails = async () => {
      if (!tokenContract || !tokenContractBytes32) {
        console.error('Contract could not be initialized.');
        setTokenDetails(null);
        return;
      }

      try {
        const namePromise = tokenContract.name();
        const symbolPromise = tokenContract.symbol();
        const decimalsPromise = tokenContract.decimals();


        const nameBytes32Promise = tokenContractBytes32.name().catch(() => '');
        const symbolBytes32Promise = tokenContractBytes32.symbol().catch(() => '');

        const [name, symbol, decimals, nameBytes32, symbolBytes32] = await Promise.all([
          namePromise.catch(() => ''),
          symbolPromise.catch(() => ''),
          decimalsPromise.catch(() => 0),
          nameBytes32Promise,
          symbolBytes32Promise,
        ]);

        const parsedName = parseStringOrBytes32(name, nameBytes32, 'Unknown Token');
        const parsedSymbol = parseStringOrBytes32(symbol, symbolBytes32, 'UNKNOWN');

        const newToken = new Token(chainId, address, decimals, parsedSymbol, parsedName);
        setTokenDetails(newToken);
      } catch (error) {
        console.error(`Failed to fetch token details for address ${address}:`, error);
        setTokenDetails(null);
      }
    };

    fetchTokenDetails();
  }, [chainId, address, tokens]);

  return tokenDetails;
}
export function useCurrency(currencyId: string | undefined): Currency | null | undefined {
  const isETH = currencyId?.toUpperCase() === 'ETH';
  const token = useToken(isETH ? undefined : currencyId);
  return isETH ? ETHER : token;
}
