import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { getEtherscanLink } from '../../utils'

interface BackdropProps {
    status: 'doing' | 'done-pass' | 'done-fail' | 'done-cnt' | 'done-cnt-2' | 'cnt-invalid' | 'sign';
}


// Interface for the component's props
interface ModalTnxProps {
    status: 'doing' | 'done-pass' | 'done-fail' | 'done-cnt' | 'done-cnt-2' | 'cnt-invalid' | 'sign';
    title: string; // Title text passed from parent
    subtitle: string; // Subtitle text passed from parent
    onDismiss?: () => void; // Optional dismiss function
    url?: string;

}

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Backdrop = styled.div<BackdropProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    ${props => props.status === 'doing' && 'pointer-events: none;'} // Disable backdrop close if status is 'doing'
`;

const ModalWrapper = styled.div`
    width: 480px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 32px;
    background: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
`;

interface SpinnerProps {
    status: 'doing' | 'done-pass' | 'done-fail' | 'done-cnt' | 'done-cnt-2' | 'cnt-invalid' | 'sign';
}

const Spinner = styled.div<SpinnerProps>`
    width: 48px;
    height: 48px;
    border: 4px solid ${props => props.status === 'done-pass' ? '#28a745' : (props.status === 'done-fail' ? '#dc3545' : '#BFCAFF')};
    border-top-color: ${props => props.status === 'done-pass' ? '#28a745' : (props.status === 'done-fail' ? '#dc3545' : '#3253FF')};
    border-radius: 50%;
    animation: ${props => props.status === 'doing' ? css`${spinAnimation} 1s linear infinite` : 'none'};
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: #333;
    margin-top: 20px;
`;

const Subtitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-top: 10px;
`;

const ExternalLink = styled.a`
    color: #007bff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

// Component function with props destructuring and TypeScript annotations
export default function ModalTnx({ status, title, subtitle, onDismiss, url }: ModalTnxProps) {
    const renderContent = () => {
        switch (status) {
            case 'done-pass':
                return (
                    <>
                        <Spinner status="done-pass" />
                        <Title>Transaction Completed</Title>
                        <Subtitle>{subtitle} <ExternalLink href={url} target={'_blank'}>View on CAGA Explorer</ExternalLink></Subtitle>
                    </>
                );
            case 'done-fail':
                return (
                    <>
                        <Spinner status="done-fail" />
                        <Title>Transaction Failed</Title>
                        <Subtitle>
                            {subtitle}
                            {url && <ExternalLink href={url} target="_blank">View on CAGA Explorer</ExternalLink>}
                        </Subtitle>
                    </>
                );
            case 'done-cnt':
                return (
                    <>
                        <Spinner status="done-fail" />
                        <Title>Please try again later </Title>
                        <Subtitle>You have reached your daily limit of swapping 1 CAGA for CNT. Please wait until the next day (UTC+0) to perform another swap.</Subtitle>
                    </>
                );
            case 'done-cnt-2':
                return (
                    <>
                        <Spinner status="done-fail" />
                        <Title>Please try again later</Title>
                        <Subtitle>You can only swap a maximum of 1 CAGA for CNT within a day (00:00 to 23:59 UTC+0). Ensure the total amount swapped does not exceed this limit.</Subtitle>
                    </>
                );
            case 'cnt-invalid':
                return (
                    <>
                        <Spinner status="done-fail" />
                        <Title>Please try again later</Title>
                        <Subtitle>Swap Error: Ensure your total swaps of CAGA for CNT do not exceed 1 CAGA within a day (00:00 to 23:59 UTC+0). Please adjust your swap amount accordingly</Subtitle>
                    </>
                );
            case 'sign':
                return (
                    <>
                        <Spinner status="doing" />
                        <Title>Sign Your Transaction</Title>
                        <Subtitle>Please confirm transaction in your wallet to proceed.</Subtitle>
                    </>
                );
            case 'doing':
            default:
                return (
                    <>
                        <Spinner status="doing" />
                        <Title>{title}</Title>
                        <Subtitle>{subtitle}</Subtitle>
                    </>
                );
        }
    };

    return (
        <Backdrop onClick={onDismiss ? onDismiss : undefined} status={status}>
            <ModalWrapper onClick={(e) => e.stopPropagation()}>
                {renderContent()}
            </ModalWrapper>
        </Backdrop>
    );
}
