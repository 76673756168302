import React from 'react';
import { Helmet ,HelmetProvider} from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  h1?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, h1 }) => {
  const defaultTitle = "CAGA Crypto Testnet: Secure and Reliable Blockchain Testing Environment";
  const defaultDescription = "Explore the CAGA Testnet for secure and risk-free blockchain development and testing. Get started with test tokens, smart contracts, and more on our robust EVM-compatible network.";
  const defaultH1 = "Welcome to CAGA Crypto Testnet";

  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
      </Helmet>
      <h1 className="hidden-header">{h1 || defaultH1}</h1>
    </>
    </HelmetProvider>
  );
};

export default SEO;