import React from 'react';
import styled from 'styled-components';
import SepoliaAdvancedSwapDetails from './SepoliaAdvancedSwapDetails';

interface SepoliaAdvancedSwapDetailsDropdownProps {
  show: boolean;
  buyToken: string;
  sellAmount: string;
}

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  padding-bottom: 20px;
  margin-top: -2rem;
  margin-bottom: 36px;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => theme.advancedBG};
  z-index: -1;
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`;

export default function SepoliaAdvancedSwapDetailsDropdown({ show, buyToken, sellAmount }: SepoliaAdvancedSwapDetailsDropdownProps) {
  return (
    <AdvancedDetailsFooter show={show}>
      <SepoliaAdvancedSwapDetails 
        buyToken={buyToken} 
        sellAmount={sellAmount}
      />
    </AdvancedDetailsFooter>
  );
}
