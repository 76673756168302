import { createRoot } from 'react-dom/client';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import 'inter-ui';
import React, { StrictMode, useEffect } from 'react';
import { Provider } from 'react-redux';
import { NetworkContextName } from './constants';
import './i18n';
import App from './pages/App';
import store from './state';
import ApplicationUpdater from './state/application/updater';
import ListsUpdater from './state/lists/updater';
import TransactionUpdater from './state/transactions/updater';
import UserUpdater from './state/user/updater';
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme';
import getLibrary from './utils/getLibrary';
import { Web3ReactProvider as Web3ReactCustomizeProvider } from './contexts/Web3Context';
import ErrorBoundary from './components/ErrorBoundary';
import './globalErrorHandlers';
import { initGA, logPageView } from './ga4'; // Import GA4 functions
import { initializeGTM } from './gtm'; // Import GTM initialization

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

function Updaters(): JSX.Element {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
    </>
  );
}

const container = document.getElementById('root')!;
const root = createRoot(container);

function AppWrapper() {
  useEffect(() => {
    initializeGTM();
    initGA();
    logPageView();
    const handleRouteChange = () => {
      logPageView();
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
    <StrictMode>
      <FixedGlobalStyle />
      <Web3ReactCustomizeProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <Updaters />
              <ThemeProvider>
                <ThemedGlobalStyle />
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </ThemeProvider>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </Web3ReactCustomizeProvider>
    </StrictMode>
  );
}

root.render(<AppWrapper />);
