import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ModalRelogin from '../ModalRelogin'; // Ensure this path is correct
import { useWeb3ReactContext } from '../../contexts/Web3Context';

const WalletConnectModal: React.FC = () => {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const { account } = useWeb3ReactContext();

    useEffect(() => {
        const params = queryString.parse(location.search);
        const isWalletConnected = params.iswalletconnected === 'true';

        // Using a timer to delay the modal display check
        let timer: NodeJS.Timeout;
        if (isWalletConnected) {
            timer = setTimeout(() => {
                // Only set the modal to show if there is no account
                if (!account) {
                    setShowModal(true);
                }
            }, 500); // Delay of 500 ms
        }

        // Cleanup function to clear the timeout if the component unmounts or dependencies change
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [location, account]); // Depend on location and account from your Web3 context

    const handleClose = () => setShowModal(false);

    return (
        <>
            {showModal && (
                <ModalRelogin onDismiss={handleClose} />
            )}
        </>
    );
};

export default WalletConnectModal;
