import React from 'react';
import styled, { keyframes } from 'styled-components';

// Interface for the component's props
interface ModalReloginProps {
    onDismiss: () => void; // Function to call on dismiss
}

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalWrapper = styled.div`
    width: 335px;
    height: 231px;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 32px;
    border-bottom: 1px solid #DDDDDD;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    background: white;
`;

const SpinnerContainer = styled.div`
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const Spinner = styled.div`
    width: 48px;
    height: 48px;
    border: 4px solid #BFCAFF;
    border-top-color: #3253FF;
    border-radius: 50%;
    animation: ${spinAnimation} 1s linear infinite;
`;

const Message = styled.div`
    color: #6A6A6A;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
`;

const Button = styled.button`
    width: 249px;
    height: 51px;
    border-radius: 48px;
    background: linear-gradient(90deg, #2400FF 0%, #339DE9 100%);
    color: white;
    font-size: 16px;
    padding: 16px 0;
    border: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Component function with props destructuring and TypeScript annotations
export default function ModalRelogin({ onDismiss }: ModalReloginProps) {
    return (
        <Backdrop onClick={onDismiss}>
            <ModalWrapper onClick={(e) => e.stopPropagation()}>
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
                <Message>
                    For security reasons, you will be <br />
                    required to connect your wallet again.
                </Message>
                <Button onClick={onDismiss}>Okay, got it</Button>
            </ModalWrapper>
        </Backdrop>
    );
}
