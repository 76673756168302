import { useState, useEffect } from 'react';
import { namehash } from 'ethers/lib/utils';
import isZero from '../utils/isZero';
import { useENSRegistrarContract, useENSResolverContract } from './useContract';

interface ENSContentHashReturn {
  loading: boolean;
  contenthash: string | null;
}

const useENSContentHash = (ensName?: string | null): ENSContentHashReturn => {
  const [contentHash, setContentHash] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const registrarContract = useENSRegistrarContract(false);
  const [resolverAddress, setResolverAddress] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!ensName) {
      setContentHash(null);
      setResolverAddress(undefined);
      return;
    }
    setLoading(true);
    const ensNode = namehash(ensName);
    registrarContract?.resolver(ensNode).then((address: string) => {
      if (!isZero(address)) {
        setResolverAddress(address);
      } else {
        setResolverAddress(undefined);
        setContentHash(null);
        setLoading(false);
      }
    }).catch(() => {
      setResolverAddress(undefined);
      setContentHash(null);
      setLoading(false);
    });
  }, [ensName, registrarContract]);

  const resolverContract = useENSResolverContract(resolverAddress, false);

  useEffect(() => {
    if (!resolverAddress || isZero(resolverAddress)) {
      setContentHash(null);
      setLoading(false);
      return;
    }
    resolverContract?.contenthash().then((hash: [string]) => {
      setContentHash(hash[0] || null);
      setLoading(false);
    }).catch(() => {
      setContentHash(null);
      setLoading(false);
    });
  }, [resolverAddress, resolverContract]);

  return { contenthash: contentHash, loading };
};

export default useENSContentHash;
