import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import BridgeIcon from "../../assets/images/navbar/bridge.svg";
import DexIcon from "../../assets/images/navbar/dex.svg";
import DexBlackIcon from "../../assets/images/navbar/dex_black.svg"; // Unselected icon for DEX
import ExplorerIcon from "../../assets/images/navbar/explorer.svg"; // Selected icon for Explorer
import ExplorerBlackIcon from "../../assets/images/navbar/explorer_black.svg"; // Unselected icon for Explorer
import FaucetIcon from "../../assets/images/navbar/faucet.svg";
import GovernanceIcon from "../../assets/images/navbar/governance.svg";
import NftIcon from "../../assets/images/navbar/nft.svg";
import StakeIcon from "../../assets/images/navbar/stake.svg";
import SupportIcon from "../../assets/svg/support.svg";
import { useWeb3ReactContext } from '../../contexts/Web3Context';

// Icons object to map names to icon files
const icons = {
  governance: GovernanceIcon,
  stake: StakeIcon,
  faucet: FaucetIcon,
  dex: DexIcon,
  dexBlack: DexBlackIcon,
  explorer: ExplorerIcon,
  explorerBlack: ExplorerBlackIcon,
  bridge: BridgeIcon,
  nft: NftIcon,
};

const SideMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 219px;
  background: ${({ theme }) => theme.sideBg};
  border: 2px solid ${({ theme }) => theme.bg1};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14);
  position: relative;
  top: -70px !important;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;

  @media (max-width: 1979px) {
    top: -20px ! important;
  }

  @media (min-width: 1536px) {
    /* Consider if you need specific styles for larger screens, potentially adjust padding or other properties */
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease; // Smooth transition for hover and selection changes

  &:hover,
  &.selected {
    background-color: #f0f0f0; // Default hover background
  }

  &.selected {
    background: linear-gradient(
      90deg,
      #2606ff 0%,
      #3395ec 100%
    ); // Gradient background for selected items
    color: #ffffff; // Adjust text color for better visibility on the gradient background
    font-weight: 700;
  }
`;

const Icon = styled.img<{ isSelected: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: ${({ isSelected }) => (isSelected ? 'none' : 'invert(0.5)')};
`;

const ContactSupport = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease; // Smooth transition for hover and selection changes;

  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

const handleExternalNavigation = (url: string) => {
  if (url) {
    window.location.href = url; // Use window.location.href for external navigation
  }
};

const SideMenu = () => {
  const location = useLocation();

  const windowWidth = useWindowWidth();
  const isMobileWidth = windowWidth <= 768;
  const [height, setHeight] = useState('calc(100vh - 100px)');

  const { account } = useWeb3ReactContext();
  const getMenuLink = (baseLink: string) => {
    return account && baseLink ? `${baseLink}?iswalletconnected=true` : baseLink;
  };

  const menuItems = [
    { name: "Faucet", icon: icons.faucet, link: getMenuLink("https://portal-testnet.cagacrypto.com/faucet") },
    { name: "DEX", icon: icons.dex, link: "/#/swap", unselectedIcon: icons.dexBlack, paths: ['/swap', '/sell', '/send', '/pool', '/add'] },
    { name: "Explorer", icon: icons.explorer, link: "/#/explorer", unselectedIcon: icons.explorerBlack, paths: ['/explorer','/pairdetails'] },
    { name: "Bridge", icon: icons.bridge, link: getMenuLink("https://portal-testnet.cagacrypto.com/bridge") },
    { name: "NFT", icon: icons.nft, link: getMenuLink("https://portal-testnet.cagacrypto.com/nft") },
  ];

  useEffect(() => {
    const updateHeight = () => {
      setHeight(`calc(${window.innerHeight}px - 150px)`);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Initial update

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const checkIsSelected = (path: string, paths: string[]) => {
    const basePath = path.split('?')[0];
    return paths.some(p => basePath.startsWith(p));
  };

  if (isMobileWidth) {
    return null;
  }

  return (
    <SideMenuContainer style={{ height: height, maxHeight: '764px' }}>
      <div style={{ width: '183px' }}>
        {menuItems.map((item, index) => {
          const isSelected = checkIsSelected(location.pathname, item.paths || [item.link.replace('/#', '')]);
          return (
            <MenuItem
              key={index}
              className={isSelected ? "selected" : ""}
              onClick={() => handleExternalNavigation(item.link)}
            >
              <Icon src={isSelected ? item.icon : item.unselectedIcon || item.icon} alt={`${item.name} icon`} isSelected={isSelected} />
              {item.name}
            </MenuItem>
          );
        })}
      </div>
      <ContactSupport
        onClick={() => handleExternalNavigation('https://www.cagacrypto.com/contact-us/0')}
      >
        <Icon src={SupportIcon} alt={`SupportIcon`} isSelected={false} />
        Contact Support
      </ContactSupport>
    </SideMenuContainer>
  );
};

export default SideMenu;