import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Currency } from '@caga-cryptos/cagaswap-v2-sdk'
import { ThemeContext } from 'styled-components';
import { TYPE } from '../../theme';
import { ethers } from 'ethers';
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import ERC20_INTERFACE from '../../constants/abis/erc20.json';
import AppBody from '../AppBody';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import { ButtonPrimary, ButtonLight } from '../../components/Button';
import { Wrapper, BottomGrouping } from '../../components/Swap/styleds';
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal';
// Import the dropdown icon

import { useCurrency } from '../../hooks/Tokens';
import { useWalletModalToggle } from '../../state/application/hooks';
import {
  Spinner,
  SmallSpinner,
  BalanceDisplay,
  CurrencyDisplay,
  StyledTokenName,
  Section,
  Info,
  BalanceValue,
  Value,
  ButtonContainer, StyledCurrencyIcon, StyledDropDown
} from './styled';


const CTT_TOKEN_ADDRESS = '0x47e405b514068e1963fbf84006009bea4edd26b2';
interface TokenAddresses {
  [key: string]: string;
}
const TOKEN_ADDRESSES: TokenAddresses = {
  USDC: '0xa4eE9C98643403cF6555728272129c721EF4c266',
  USDT: '0x3637925eE8B837f85c7309e4b291Ca56A40457a4',
  DOGE: '0xE1eC108ba9fB9B8A691848d9C5A34aA5fBaA6e23',
  WBTC: '0x835EF3b3D6fB94B98bf0A3F5390668e4B83731c5',
  CTT: '0x2514c246226EEE6EF9F3aB017Be2c01FAa3312dD',
};
const CONTRACT_ADDRESS = '0x7F1b29A5b052260b669Cb8E39139e007c03F2dEc';
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_value",
        "type": "uint256",
      },
    ],
    "name": "charge",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function",
  },
];


const DownArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#C3C5CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="19 12 12 19 5 12"></polyline>
  </svg>
);




export default function Buy() {
  const { account, library } =useWeb3ReactContext();
  const toggleWalletModal = useWalletModalToggle()
  const theme = useContext(ThemeContext);
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(useCurrency('ETH')); // Initial currency selection
  const [selectedOutputCurrency, setSelectedOutputCurrency] = useState(useCurrency(''));
  const isSelected = selectedOutputCurrency !== null;

  const [transactionStatus, setTransactionStatus] = useState({
    message: '',
    link: '',
    linkText: '',
    isSuccess: false
  });
  const [isBuying, setIsBuying] = useState(false);
  const [cttBalance, setCttBalance] = useState('');
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenSearch = () => {
    setModalOpen(true);
  };

  const handleDismissSearch = () => {
    setModalOpen(false);
  };

  interface ExtendedCurrency extends Currency {
    logoURI?: string;
    address?: string;

  }

  const allowedSymbols = ["CTT", "USDT", "USDC", "DOGE", "WBTC"];
  const isAllowedCurrency = allowedSymbols.includes(selectedOutputCurrency?.symbol || "CTT");


  const fetchOutputBalance = useCallback(async () => {
    if (!account || !library) return;

    // Use selectedOutputCurrency's address if available; otherwise, fallback to CTT_TOKEN_ADDRESS
    const tokenAddress = (selectedOutputCurrency as ExtendedCurrency)?.address || CTT_TOKEN_ADDRESS;

    const tokenContract = new ethers.Contract(tokenAddress, ERC20_INTERFACE, library);
    const balance = await tokenContract.balanceOf(account);

    // Assuming the token decimals might not always be 18, handle dynamically if possible
    // For simplicity, this example assumes 18 decimals. Adjust based on your application's needs or token's actual decimals
    const decimals = 18; // This could be dynamic if your app supports tokens with varying decimals
    const balanceFormatted = parseFloat(ethers.utils.formatUnits(balance, decimals)).toFixed(4);

    setCttBalance(balanceFormatted);

  }, [account, library, selectedOutputCurrency]); // Include selectedOutputCurrency in the dependency array


  useEffect(() => {
    fetchOutputBalance();
  }, [fetchOutputBalance]);


  const handleMaxInput = async () => {
    if (!account || !library) {
      return;
    }
  
    try {
      // Fetching the balance
      const balance = await library.getBalance(account);
      // ethers.js returns the balance in wei, convert it to ether
      const balanceInEth = ethers.utils.formatEther(balance);
      // Convert to fixed decimal places and back to a float if needed
      const balanceToDisplay = parseFloat(balanceInEth).toFixed(4);
      // Set the formatted balance as the input value
      setAmount(balanceToDisplay);
    } catch (error) {
      console.error('Failed to fetch ETH balance:', error);
    }
  };


  const handleBuy = async () => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const baseUrl = isLocalhost ? '' : 'https://www.ankara-cagacrypto.com';

    if (!account || !library || !amount || isNaN(parseFloat(amount))) {
      setTransactionStatus({
        message: 'Please connect your wallet and enter a valid amount.',
        link: '',
        linkText: '',
        isSuccess: false
      });
      return;
    }

    setIsBuying(true); // Start loading indicator
    setLoadingBalance(true);
    setTransactionStatus({ message: "Please wait, approving...", link: '', linkText: '', isSuccess: true });

    let resp;
    try {
      const signer = library.getSigner(account);
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

      // Assuming immediate transaction sending for simulation
      const txResponse = await contract.charge(
        ethers.utils.parseUnits(amount, 'ether'), // Convert to BigNumber for contract parameter
        { value: ethers.utils.parseEther(amount) } // Specify Ether to send with the transaction
      );

      await txResponse.wait(); // Wait for the transaction to be mined


      setTransactionStatus({ message: "Please wait, purchasing...", link: '', linkText: '', isSuccess: false });
      //console.log("Status set to purchasing, awaiting further updates...");


      // Simulate a delay for the approval process

    
      const selectedTokenAddress = TOKEN_ADDRESSES[selectedOutputCurrency?.symbol ?? 'CTT'];

      const payload = {
        wallet: account,
        token: selectedTokenAddress,
        value: ethers.utils.parseEther(amount).toString()
      };

      //console.log(payload)

      const response = await fetch(`${baseUrl}/dex/api/v1/transaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      resp=data;

      setIsBuying(false); // Stop loading indicator

      setTransactionStatus({
        message: `Transaction successful! Retrieving New Balance...`,
        link: data.FinalTxToUser,
        linkText: "View Transaction",
        isSuccess: true
      });

      if (data.statusMsg === "200 OK") {
        // Success, update your state or UI as needed
        setTimeout(async () => {
          await fetchOutputBalance(); // Refresh CTT balance

          setTransactionStatus({
            message: `Transaction successful!`,
            link: data.body.FinalTxToUser,
            linkText: "View Transaction",
            isSuccess: true
          });
          setLoadingBalance(false);
        }, 10000); // Additional delay for "retrieving new balance"
      } else {
        throw new Error('API response indicated failure.');
      }

    } catch (error) {
      console.error("Purchase failed: ", error);
      setIsBuying(false); // Stop loading indicator
      setLoadingBalance(false);
      setTransactionStatus({
        message: 'Transaction failed.' + resp.statusMsg,
        link: '',
        linkText: '',
        isSuccess: false
      });
    }
  };



  return (
    <>
      <AppBody>
        <SwapPoolTabs active='buy' />
        <Wrapper id="buy-page">
          <CurrencyInputPanel
            label="Amount to Spend (ETH)"
            value={amount}
            onUserInput={setAmount}
            onCurrencySelect={setSelectedCurrency}
            showMaxButton={true}
            onMax={handleMaxInput}
            currency={selectedCurrency}
            id="send-currency-input"
            disableCurrencySelect={true}
          />

          <div style={{ display: 'flex', justifyContent: 'left', margin: '12px 1rem' }}>
            <DownArrowIcon />
          </div>

          <BalanceDisplay>
            <Section>
              <Info>{selectedOutputCurrency?.symbol || 'CTT'} to be received</Info>
              <BalanceValue>-.-</BalanceValue> {/* Dynamically update based on state */}
            </Section>
            <Section>
              <Info>
                Balance: <Value>{loadingBalance ? <SmallSpinner /> : cttBalance || '-'}</Value>
              </Info>
              <CurrencyDisplay selected={true}> {/* Assume selected state or dynamic based on your logic */}
                <ButtonContainer onClick={handleOpenSearch} selected={isSelected}>
                  <StyledCurrencyIcon>
                    <img src={(selectedOutputCurrency as ExtendedCurrency)?.logoURI || 'https://www.cagacrypto.com/tokens/caga.svg'} alt="Currency" />
                  </StyledCurrencyIcon>
                  <StyledTokenName>{selectedOutputCurrency?.symbol || 'CTT'}</StyledTokenName>
                  {/* Assuming you have a component or method to render the dropdown icon */}
                  <StyledDropDown selected={isSelected} />
                </ButtonContainer>

              </CurrencyDisplay>
            </Section>
          </BalanceDisplay>

          <CurrencySearchModal
            isOpen={modalOpen}
            onDismiss={handleDismissSearch}
            onCurrencySelect={setSelectedOutputCurrency} // Use the new setter function here
            selectedCurrency={selectedOutputCurrency} // Use the new state here
          />

          <BottomGrouping>
            {!account ? (
              // Show "Connect to Wallet" if no account is connected
              <ButtonLight onClick={toggleWalletModal}>
                Connect to Wallet
              </ButtonLight>
            ) : (
              <ButtonPrimary
                onClick={handleBuy}
                disabled={!account || !amount || isBuying || loadingBalance || !isAllowedCurrency}>
                {isBuying ? <Spinner /> : `Buy ${selectedOutputCurrency?.symbol || 'CTT'}`}
              </ButtonPrimary>
            )}
            {transactionStatus.message && (
              <div style={{ paddingTop: '10px', textAlign: 'center' }}>
                <TYPE.body>
                  {transactionStatus.message} <br />
                  {transactionStatus.isSuccess && (
                    <a href={transactionStatus.link} target="_blank" rel="noopener noreferrer" style={{ color: theme.green1 }}>
                      {transactionStatus.linkText}
                    </a>
                  )}
                </TYPE.body>
              </div>
            )}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
    </>
  );
}
