import styled from 'styled-components';


export const MainContainer = styled.div`
  display: flex;
  max-width: 1240px;
  width: 100%; // Use 100% width up to the max width
  margin: 0 auto; // Center the container within BodyWrapper
  position: relative;
  align-items: flex-start; // Ensure items start from the top
  padding-left: 5px;

  @media (max-width: 1280px) {
    padding-left: 2%;
  }
 
  @media (max-width: 768px) {
    padding-left: 1%;
    padding-right: 1%;
    margin-top: 12px !important;
  }
`;


export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

`;