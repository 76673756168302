import { useState, useEffect } from 'react';
import { Token, TokenAmount } from '@caga-cryptos/cagaswap-v2-sdk';
import { useTokenContract } from '../hooks/useContract';

// Assuming useTokenContract abstracts away ethers and simply returns a contract instance

export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const [totalSupply, setTotalSupply] = useState<TokenAmount | undefined>(undefined);
  const contract = useTokenContract(token?.address, false);

  useEffect(() => {
    if (!token || !contract) {
      setTotalSupply(undefined);
      return;
    }

    let isMounted = true;

    const fetchTotalSupply = async () => {
      try {
        const totalSupplyValue = await contract.totalSupply();

        // Check if totalSupplyValue is not zero or any falsey value before setting
        if (isMounted) {
          if (totalSupplyValue && !totalSupplyValue.isZero()) {
            setTotalSupply(new TokenAmount(token, totalSupplyValue.toString()));
          } else {
            // For debugging: explicitly log this scenario
            setTotalSupply(undefined);
          }
        }
      } catch (error) {
        console.error('Failed to fetch total supply:', error);
        if (isMounted) {
          setTotalSupply(undefined);
        }
      }
    };

    fetchTotalSupply();

    return () => {
      isMounted = false;
    };
  }, [token, contract]);

  return totalSupply;
}