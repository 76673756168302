// import { ChainId } from "@caga-cryptos/cagaswap-v2-sdk";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "../../assets/images/logo.png";
import LogoDark from "../../assets/images/logo.png";
import { useDarkModeManager } from "../../state/user/hooks";
import { RowBetween } from "../Row";
import { HeaderTabs } from "../HeaderTabs";
import Web3ModalComponent from "../Web3Modal";

const HeaderFrame = styled.div`
  display: -webkit-box;
  margin-top: 0.8rem !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 96%;

  margin: 0 auto;
  @media (max-width: 768px) {
    height: 48px !important;
    margin: 0;
    margin-right: 4.8% !important;
  }
  height: 61px !important;
  max-width: 1240px;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 9999px;
  padding: 0.5rem;
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  :hover {
    cursor: pointer;
  }
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
`;
/*
const TestnetWrapper = styled.div`
  white-space: nowrap;
  margin-left: 10px;
`;

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
`;
*/
const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
  width: 48px;
  height: 48px;

`;

const HeaderControls = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  
`;

/*
const BalanceText = styled(Text)`
  display: flex;
`;
*/

const CagaText = styled.span`
  letter-spacing: -0.02em;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 0.5rem !important;
  }
  font-size: 24px;

  font-weight: 800;
  margin-left: 1rem;
`;

const PortalText = styled.span`
@media (max-width: 768px) {
  font-size: 16px;
}
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-left:5px;
  margin-right: 16px; // Adjust spacing to the right towards the frame
`;

// Outer div for the gradient border
const GradientBorderFrame = styled.div`
  display: inline-block; // Inline block for alignment
  padding: 1px; // Space for the gradient border
  background: linear-gradient(
    44.36deg,
    #ffb631 17.57%,
    #ff943b 32.88%,
    #ff604a 55.91%
  ); // Gradient
  border-radius: 5px; // Slightly larger radius to accommodate inner radius
`;

// Inner div for white background and border-radius
const InnerBorderFrame = styled.div`
  background: white; // White background
  border-radius: 4px; // Actual desired radius
  padding: 3px 8px; // Padding for content
  height: 100%; // Full height of the parent
`;

// Text with gradient color
const GradientText = styled.span`
  font-size: 14px;
  font-weight: 550;
  color: transparent; // Needed for gradient text
  background: linear-gradient(
    44.36deg,
    #ffb631 17.57%,
    #ff943b 32.88%,
    #ff604a 55.91%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; // Gradient text effect
  display: block; // Center the text
  text-align: center;
  font-family: 'DM Mono', monospace; // Using DM Mono font
`;

// Styled container for the label
const LabelContainer = styled.div`
  width: 120px;
  height: 40px;
  padding: 8px 12px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 40px;
  border: 1px solid #EEEEEE;
  margin-right: 20px;
`;

// Styled icon
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px; // Space between icon and text
`;

// Styled text next to the icon
const LabelText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: black; // Or any other color based on theme or preference

`;
/*
const NETWORK_LABELS = {
  [ChainId.CAGA]: "Testnet",
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: "Rinkeby",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.GÖRLI]: "Görli",
  [ChainId.KOVAN]: "Kovan",
  [ChainId.XDAI]: "XDAI",
};
*/

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

export default function Header() {

  const [isDark] = useDarkModeManager();
  const windowWidth = useWindowWidth(); // Use the hook to get the current window width
  const isMobileWidth = windowWidth <= 768; // Define mobile width threshold

  return (
    <>
      {isMobileWidth && <HeaderTabs />}
      <HeaderFrame>
        <RowBetween style={{ alignItems: "center", width: "100%" }}>
          <HeaderElement>
            <Title>
              <UniIcon>
                <img
                  src={isDark ? LogoDark : Logo}
                  alt='logo'
                  style={{ width: !isMobileWidth ? 48 : 32, height: !isMobileWidth ? 48 : 32 }}
                />
              </UniIcon>
            </Title>

            <div style={{ display: "inline" }}>
              {" "}
              {/* Ensure this container is inline */}
              <CagaText>CAGA</CagaText>


            </div>
            <PortalText> Portal</PortalText>
            {!isMobileWidth && (
              <GradientBorderFrame>
                <InnerBorderFrame>
                  <GradientText>Running on Testnet</GradientText>
                </InnerBorderFrame>
              </GradientBorderFrame>
            )}

            {/*
          {chainId && NETWORK_LABELS[chainId] && (
            <TestnetWrapper>
              <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
            </TestnetWrapper>
          )}*/}
          </HeaderElement>


          <HeaderControls>
            <LabelContainer>
              <Icon src={isDark ? LogoDark : Logo} alt="CAGA Logo" />
              <LabelText>CAGA</LabelText>
            </LabelContainer>
            <Web3ModalComponent />
          </HeaderControls>

        </RowBetween>
      </HeaderFrame>
    </>
  );
}