import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount } from '@caga-cryptos/cagaswap-v2-sdk'
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useAllTokens } from '../../hooks/Tokens'
import { isAddress } from '../../utils'
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';

import { useWeb3ReactContext } from '../../contexts/Web3Context';

function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const { library } = useWeb3ReactContext();
  const [balances, setBalances] = useState<{ [address: string]: CurrencyAmount | undefined }>({});

  useEffect(() => {
    if (!library || !uncheckedAddresses) {
      return;
    }

    let isSubscribed = true; // This flag will help us to determine if the component is still mounted

    const validAddresses = uncheckedAddresses
      .filter((address): address is string => address !== undefined && isAddress(address) !== false);

    const fetchBalances = async () => {
      const balancePromises = validAddresses.map(async (address) => {
        try {
          const balance = await library.getBalance(address);
          return { address, balance };
        } catch (error) {
          console.error(`Failed to fetch balance for address: ${address}`, error);
          return { address, balance: undefined };
        }
      });

      const results = await Promise.all(balancePromises);

      if (isSubscribed) {
        const newBalances = results.reduce((acc, { address, balance }) => {
          if (balance !== undefined) {
            acc[address] = CurrencyAmount.ether(JSBI.BigInt(balance.toString()));
          }
          return acc;
        }, {} as { [address: string]: CurrencyAmount | undefined });
        setBalances(newBalances);
      }
    };

    fetchBalances();

    return () => {
      isSubscribed = false; // Unset the subscription flag when the component unmounts
    };
  }, [library, uncheckedAddresses?.join(',')]);

  return balances;
}


export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokensProp?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const { library } = useWeb3ReactContext();
  const [balances, setBalances] = useState<{ [tokenAddress: string]: TokenAmount | undefined }>({});
  const [loading, setLoading] = useState(true);

  const tokenAddresses = useMemo(() => tokensProp?.map(token => token?.address).filter(isAddress) ?? [], [tokensProp]);

  useEffect(() => {
    let isActive = true;

    const fetchBalances = async () => {
      if (!library || !address || tokenAddresses.length === 0) {
        setLoading(false);
        return;
      }

      setLoading(true);
      const newBalances: { [tokenAddress: string]: TokenAmount | undefined } = {};

      await Promise.all(tokensProp?.map(async (token) => {
        if (token && isAddress(token.address)) {
          try {
            const tokenContract = new ethers.Contract(token.address, ERC20_INTERFACE, library);
            const balance = await tokenContract.balanceOf(address);
            newBalances[token.address] = new TokenAmount(token, balance.toString());
          } catch (error) {
           // console.log(`Failed to fetch balance for token at address ${token.address}:`);
          }
        }
      }) ?? []);

      if (isActive) {
        setBalances(newBalances);
        setLoading(false);
      }
    };

    fetchBalances();

    return () => {
      isActive = false;
    };
  }, [library, address, JSON.stringify(tokenAddresses)]);  // Ensuring tokenAddresses is a stable dependency

  return [balances, loading];
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}



// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => 
    currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], 
    [currencies]
  );

  const tokenBalances = useTokenBalances(account, tokens);
  const containsETH = useMemo(() => 
    currencies?.some(currency => currency === ETHER) ?? false, 
    [currencies]
  );

  const ethBalances = useETHBalances(containsETH && account ? [account] : []);

  return useMemo(() => 
    currencies?.map(currency => {
      if (!currency) return undefined;
      if (currency === ETHER && account) return ethBalances[account]; // Only access if account is defined
      if (currency instanceof Token) return tokenBalances[currency.address];
      return undefined;
    }) ?? [],
    [currencies, ethBalances, tokenBalances, account] // Ensuring account is part of dependency array
  );
}



export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  const balances = useCurrencyBalances(account, [currency]);
  return balances[0];
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useWeb3ReactContext();
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
