import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

// Define the fade-up keyframe animation
const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BodyWrapper = styled.div<{ animate: boolean }>`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  padding: 1rem;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeUp} 0.5s ease-out;
    `}
`;

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Check session storage for animation flag
    const hasAnimated = sessionStorage.getItem('hasAnimated');
    if (!hasAnimated) {
      setAnimate(true);
      sessionStorage.setItem('hasAnimated', 'true');
    }
  }, []);

  useEffect(() => {
    // Clear the session storage on page unload to re-enable animation on refresh
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('hasAnimated');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <BodyWrapper animate={animate}>{children}</BodyWrapper>;
}
