import styled, { keyframes, css } from 'styled-components';
import { darken } from 'polished';
import { NavLink } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

import { DropdownMenu as RSDropdownMenu, DropdownToggle as RSDropdownToggle } from 'reactstrap';

export const ExploreContainer = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 28px 20px;
  @media (max-width: 680px) {
    width: calc(100vw + 25px); // Subtracting padding/margin to fit within the viewport
  }
`;
// PoolList related styles
export const TableContainer = styled.div`
  width: 100%;

  overflow-x: auto;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 16px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

    @media (max-width: 680px) {
    width: calc(78vw ) !important; /* Adjusted calculation */
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background-color: #F9F9F9;
    color: rgb(125, 125, 125);
    letter-spacing: -0.01em;
  }
  th, td {
    padding: 12px;
    text-align: right;
  }
  th:first-child, td:first-child {
    text-align: left;
  }
  th {
    font-weight: bold;
    cursor: pointer;
  }
  tr {
    border-bottom: 1px solid #E0E0E0;
  }
  tr:last-child {
    border-bottom: none;
  }
`;

export const Th = styled.th`
  white-space: nowrap;
`;

export const Td = styled.td`
  white-space: nowrap;
  font-weight: bold;
`;

export const PoolName = styled(Td)`
  text-align: left;
  display: flex;
  align-items: center;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`;

export const TooltipText = styled.div`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const TokenLogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TokenLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:first-child {
    z-index: 1;
    margin-left: 20%;
  }
  &:last-child {
    margin-left: -10px;
    z-index: 2;
  }
`;

// Tabs and other general styles
export const TabsWrapper = styled.div`
  position: relative;
  padding-bottom: 1px;
  margin-bottom: 5px;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledNavLink = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 3rem;
  margin-right: 8px;
  text-decoration: none;
  color: ${({ isActive, theme }) => (isActive ? 'transparent' : 'grey')};  /* Transparent when active, grey when not */
  background-image: ${({ isActive }) => (isActive ? 'linear-gradient(90deg, #2400FF 0%, #339DE9 100%)' : 'none')};  /* Gradient background when active */
  -webkit-background-clip: ${({ isActive }) => (isActive ? 'text' : 'none')};  /* Clip background to text when active */
  font-size: 20px;
  font-weight: bold;

  &:last-child {
    margin-right: 0;
  }

  ${({ isActive, theme }) => !isActive && `
    &:hover,
    &:focus {
      color: ${theme.text1};
    }
  `}
`;

export const Underline = styled.div`
@media (max-width: 680px) {
    width: calc(78vw ) !important; /* Adjusted calculation */
  }
  height: 2px;
  background: #F5F5F5;
  width: 98%;
  margin-left: 3px;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
`;

export const ActiveIndicator = styled.div<{ left: number; width: number }>`
  height: 4px;
  background: linear-gradient(90deg,#2400FF 0%,#339DE9 100%);
  position: absolute;
  bottom: 0;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  border-radius: 4px;
   transition: left 0.2s ease, width 0.2s ease; 
`;

export const NavWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 16px;
  color: #666666;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;



export const BottomLayout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LeftLayout = styled.div`
width: 100%;
`;

export const RightLayout = styled.div`
  width: 23%;
`;

export const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const BodyWrapper = styled.div<{ animate: boolean }>`
  @media (min-width: 1980px) {
    margin-top: -50px;
  }

   @media (max-width: 768px) {
    margin-left:-50px !important;
  }

  background-color: #FFFFFF !important;
  position: relative;
  width: 90%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  padding: 1rem;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeUp} 0.5s ease-out;
    `}
`;





export const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: flex-start;
`;

const fadeDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DropdownMenuStyled = styled(RSDropdownMenu)`
  background-color: white;
  left: -30px !important;
  top: 45px !important;
  transform: translate(0, 0) !important;
  animation: ${fadeDown} 0.3s ease-out;

min-width: 100px !important;
  @media (max-width: 768px) {
    left: 0px !important;
  }
`;

export const VolumeDropdownMenuStyled = styled(RSDropdownMenu)`
  background-color: white;
  left: -6px !important;
   top: 45px !important;
  transform: translate(0, 0) !important;
  animation: ${fadeDown} 0.3s ease-out;
  width: 100px;

  min-width: 120px !important;
  @media (max-width: 768px) {
    left: 0px !important;
  }
`;

export const DropdownToggleStyled = styled(RSDropdownToggle)`
  margin-top: 6px;
  background-color: white !important;
  border: 1px solid #ced4da !important;
  display: flex;
  align-items: center;
  color: #000000 !important;

  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid black !important; /* Black color for the arrow */
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
`;

// Styled search input to match dropdown style
export const SearchInput = styled.input`
  
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6.5px 12px;
  margin-left: 3px;
  width: 150px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #6c757d;
  }
`;


const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const SpinnerIcon = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;


export const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  width: 49% !important;

  @media (max-width: 680px) {
    width: 300px !important;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const CardBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  height: 200px; // Ensure the height of the chart area
  padding: 0; // Ensure no padding
`;

export const CustomLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  font-size: 16px;
  color: black;
  font-weight: 600;

  span {
    display: block;
    &:nth-child(2) {
      font-size: 30px;
      font-weight: bold;
    }
    &:nth-child(3) {
      font-size: 10px;
      color: grey;
    }
  }
`;

export const ChartWrapper = styled.div`
  padding-top: 10px;
  padding-left: 20px;
  width: 100%;
  height: 100%;

    @media (max-width: 680px) {
    width: 300px !important;
    margin-right: 0;
    margin-left: 0;
    padding-top: 40px;
  padding-left: 0px;
  }
`;


export const PoolDetailsContainer = styled.div`
  width: 100%;
  padding: 28px 20px;
  @media (max-width: 680px) {
    width: calc(100vw + 25px);
  }
`;

export const PoolDetailsLeftLayout = styled.div`
  width: 560px !important;
`;

export const PoolDetailsRightLayout = styled.div`
  width: 280px;
`;
export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const StatCard = styled.div`
margin-left: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  h3 {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const StatRow = styled.div`

  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StatLabel = styled.span`
  font-size: 14px;
  color: #333;
`;

export const StatValue = styled.span`
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

export const BarContainer = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
`;

export const Bar = styled.div<{ percentage: number; color: string }>`
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ color }) => color};
  height: 100%;
`;

export const LinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
    
    img {
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }
  }
`;
export const LinksContainer = styled.div`
  padding-left: 20px;
  margin-top: 30px;
`;