import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as HistoryLink } from 'react-router-dom';
import { RowBetween } from '../Row';
import QuestionHelper from '../QuestionHelper';
import Settings from '../Settings';
import {
  TabsWrapper,
  Tabs,
  StyledNavLink,
  Underline,
  ActiveIndicator,
  ActiveText,
  StyledArrowLeft,
  SettingsWrapper,
} from './styled';

const activeClassName = 'ACTIVE';

export function SwapPoolTabs({ active }: { active: 'swap' | 'send' | 'buy' | 'sell' | 'pool' }) {
  const { t } = useTranslation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const [indicatorStyle, setIndicatorStyle] = useState<{ left: number; width: number }>({ left: 0, width: 0 });

  const tabs = useMemo(() => [
    { id: 'swap', to: '/swap', text: 'swap' },
    { id: 'sell', to: '/sell', text: 'sell' },
    { id: 'send', to: '/send', text: 'send' },
    { id: 'pool', to: '/pool', text: 'pool' },
  ], []);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTab = tabsRef.current.querySelector(`.${activeClassName}`) as HTMLElement;
      if (activeTab) {
        setIndicatorStyle({
          left: activeTab.offsetLeft,
          width: activeTab.offsetWidth,
        });
      }
    }
  }, [active, tabs]);

  return (
    <TabsWrapper ref={tabsRef}>
      <Tabs>
        {tabs.map((tab) => (
          <StyledNavLink
            key={tab.id}
            id={`${tab.id}-nav-link`}
            to={tab.to}
            isActive={() => active === tab.id}
          >
            {t(tab.text) as string}
          </StyledNavLink>
        ))}
      </Tabs>
      <SettingsWrapper>
        <Settings />
      </SettingsWrapper>
      <Underline />
      <ActiveIndicator
        left={indicatorStyle.left}
        width={indicatorStyle.width}
      />
    </TabsWrapper>
  );
}

export function FindPoolTabs() {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <QuestionHelper text={"Use this tool to find pairs that don't automatically appear in the interface."} />
      </RowBetween>
    </Tabs>
  );
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </Tabs>
  );
}
