import React, { Suspense } from "react";
// import React, { Suspense, useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Header from "../components/Header";
import Popups from "../components/Popups";
import Web3ReactManager from "../components/Web3ReactManager";
import DarkModeQueryParamReader from "../theme/DarkModeQueryParamReader";
import WalletConnectModal from "../components/WalletConnectModal";
import AddLiquidity from "./AddLiquidity";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from "./AddLiquidity/redirects";
import Pool from "./Pool";
import SendToken from "./Send";
import BuyToken from "./Buy";
import SellToken from "./Sell";
import PoolFinder from "./PoolFinder";
import RemoveLiquidity from "./RemoveLiquidity";
import { RedirectOldRemoveLiquidityPathStructure } from "./RemoveLiquidity/redirects";
import Swap from "./Swap";
import Explorer from "./Explorer"; // Import the Explorer component
import PairDetails from './Explorer/PairDetails';
import SEO from '../components/SEO';
import { RedirectPathToSwapOnly, RedirectToSwap } from "./Swap/redirects";
// import { BottomTabs } from "../components/BottomTabs";
import Web3Status from "../components/Web3Status";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow-x: hidden;
  align-items: stretch;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media (max-width: 1979px) {
    padding-top: 50px; ! important;
  }
  padding-top: 100px;
 
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${isMobile ? "20px" : "0px"};
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 16px;
  `};
`;

/*
const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
`;
*/

const Marginer = styled.div`
  margin-top: 5rem;
`;

/*
const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};
*/

export default function  App() {
  // const windowWidth = useWindowWidth(); // Use the hook to get the current window width
  // const isMobileWidth = windowWidth <= 768; // Define mobile width threshold

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={DarkModeQueryParamReader} />
        <Route component={WalletConnectModal} />
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Switch>
              <Route exact strict path='/swap'>
                  <>
                    <SEO
                      title="CAGA Crypto DEX - Swap"
                      description="Swap your digital assets seamlessly on CAGA Crypto's decentralized exchange. Experience fast and secure token swaps."
                      h1="Swap Tokens on CAGA Crypto DEX"
                    />
                    <Swap />
                  </>
                </Route>
                <Route
                  exact
                  strict
                  path='/swap/:outputCurrency'
                  component={RedirectToSwap}
                />
                <Route exact strict path='/buy' component={BuyToken} />
                <Route exact path='/sell'>
                  <>
                    <SEO
                      title="CAGA Crypto DEX - Sell"
                      description="Sell your tokens securely and with confidence on CAGA Crypto's decentralized exchange."
                      h1="Sell Tokens on CAGA Crypto DEX"
                    />
                    <SellToken />
                  </>
                </Route>
                <Route exact path='/send'>
                  <>
                    <SEO
                      title="CAGA Crypto DEX - Send"
                      description="Send your digital assets quickly and securely using CAGA Crypto's DEX. Transfer tokens with ease and confidence."
                      h1="Send Tokens Using CAGA Crypto DEX"
                    />
                    <SendToken />
                  </>
                </Route>
                <Route exact strict path='/find' component={PoolFinder} />
                <Route exact path='/pool'>
                  <>
                    <SEO
                      title="CAGA Crypto DEX - Pool"
                      description="Join liquidity pools on CAGA Crypto's decentralized exchange. Provide liquidity and earn rewards in a decentralized environment."
                      h1="Join Liquidity Pools on CAGA Crypto DEX"
                    />
                    <Pool />
                  </>
                </Route>
                <Route
                  exact
                  strict
                  path='/create'
                  component={RedirectToAddLiquidity}
                />
                <Route exact path='/add' component={AddLiquidity} />
                <Route
                  exact
                  path='/add/:currencyIdA'
                  component={RedirectOldAddLiquidityPathStructure}
                />
                <Route
                  exact
                  path='/add/:currencyIdA/:currencyIdB'
                  component={RedirectDuplicateTokenIds}
                />
              
                <Route
                  exact
                  strict
                  path='/remove/:tokens'
                  component={RedirectOldRemoveLiquidityPathStructure}
                />
                <Route
                  exact
                  strict
                  path='/remove/:currencyIdA/:currencyIdB'
                  component={RemoveLiquidity}
                />
               

                <Route exact path='/explorer'>
                  <>
                    <SEO
                      title="CAGA Crypto DEX - Explorer"
                      description="Check DEX Data on CAGA Crypto DEX"
                      h1="Check DEX Data on CAGA Crypto DEX"
                    />
                  <Explorer/>
                  </>
                </Route>
                <Route path="/pairdetails/:pool_id" component={PairDetails} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
              
            </Web3ReactManager>
            <Web3Status />
            <Marginer />
          </BodyWrapper>
          {/*
          {isMobileWidth && (
            <FooterWrapper>
              <BottomTabs active={null} />
            </FooterWrapper>
          )}
        */}
        </AppWrapper>
      </HashRouter>
    </Suspense>
  );
}
