import React, { useCallback, useContext, useState, useEffect, useRef, CSSProperties } from 'react';
import { CurrencyAmount } from '@caga-cryptos/cagaswap-v2-sdk'
import { Field } from '../../state/swap/actions'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { ThemeContext } from 'styled-components';
import { Currency } from '@caga-cryptos/cagaswap-v2-sdk'
import { TYPE } from '../../theme';
import { ethers } from 'ethers';
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import AppBody from '../AppBody';
import { MainContainer, ContentContainer } from '../LayoutStyles';
import SideMenu from '../../components/SideMenu';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import { ButtonPrimary } from '../../components/Button';
import { Wrapper, BottomGrouping } from '../../components/Swap/styleds';
import InformationSection from '../../components/InformationSection'
import EthIcon from "../../assets/images/eth.svg";
// Import the dropdown icon
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import {
  useSwapState,
  useSwapActionHandlers,
  useDerivedSwapInfo,
} from '../../state/swap/hooks'
import { useIsDarkMode } from '../../state/user/hooks'
import { useWalletModalToggle } from '../../state/application/hooks';
import {
  Spinner,
  SmallSpinner,
  BalanceDisplay,
  CurrencyDisplay,
  StyledTokenName,
  Section,
  Column,
  Info,
  BalanceValue,
  Value,
  ButtonContainer, StyledCurrencyIcon
} from './styled';
import { useWeb3Modal } from '@web3modal/ethers5/react'
import ModalTnx from "../../components/ModalTnx";

const DownArrowIcon = ({ style, strokeColor }: { style?: CSSProperties, strokeColor?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke={strokeColor || "#C3C5CB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={style}>
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="19 12 12 19 5 12"></polyline>
  </svg>
);



export default function Sell() {
  const { account, library } = useWeb3ReactContext();
  const toggleWalletModal = useWalletModalToggle()
  const theme = useContext(ThemeContext);
  const [amount, setAmount] = useState('');
  const { open } = useWeb3Modal()
  const [modalOpen, setModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState<'doing' | 'done-pass' | 'done-fail'>('doing');
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubtitle, setModalSubtitle] = useState('');
  const [modalUrl, setModalUrl] = useState('');

  interface ExtendedCurrency extends Currency {
    logoURI?: string;
    address?: string;

  }

  const [ethBalance, setEthBalance] = useState('');
  const ethBalanceRef = useRef(ethBalance);

  const [transactionStatus, setTransactionStatus] = useState({
    message: '',
    link: '',
    linkText: '',
    isSuccess: false
  });
  const [isBuying, setIsBuying] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const {
    currencies,
    currencyBalances,
    parsedAmount,
  } = useDerivedSwapInfo()

  const allowedSymbols = ["CTT", "USDT", "USDC", "DOGE", "WBTC"];
  const isAllowedCurrency = allowedSymbols.includes(currencies[Field.INPUT]?.symbol || "CTT");



  const fetchBalance = useCallback(async () => {
    if (!account) {
      //console.log("Wallet not connected");
      setEthBalance(''); // Ensure balance is reset if no wallet is connected
      return;
    }
    setLoadingBalance(true);
    //const apiKey = "7S18T4CUAZKGYZ68RWRXQ9PG6BVHNE7FXD"; // Etherscan API Key
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const baseUrl = isLocalhost ? '' : 'https://www.ankara-cagacrypto.com';
    const apiUrl = `${baseUrl}/dex/api/v1/balance?address=${account}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.body.status === "1") {
        const balanceInEth = ethers.utils.formatEther(data.body.result);
        // Fix the balance to display up to 4 decimal places
        const fixedBalance = Number(balanceInEth).toFixed(8);
        setEthBalance(fixedBalance);
      } else {
        console.error("Error fetching balance: ", data.statusMsg);
        setEthBalance('Error'); // Consider setting to '-' or 'Unavailable' based on your UI needs
      }
    } catch (error) {
      console.error("Error fetching balance: ", error);
      setEthBalance('Error'); // Or another placeholder text indicating failure
    } finally {
      setLoadingBalance(false);
    }
  }, [account]); // Dependency on account ensures this effect runs again if the account changes

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);



  const { independentField, typedValue } = useSwapState()
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  const { wrapType } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const parsedAmounts =
  {
    [Field.INPUT]: parsedAmount,
    [Field.OUTPUT]: parsedAmount
  }


  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }


  const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
      setAmount(value);
    },
    [onUserInput]
  )
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])

  const handleInputSelect = useCallback(
    inputCurrency => {

      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      const maxAmount = maxAmountInput.toExact(); // Get the exact amount from maxAmountInput
      onUserInput(Field.INPUT, maxAmount); // Update the input field
      setAmount(maxAmount); // Update the local state to reflect this change
    }
  }, [maxAmountInput, onUserInput, setAmount]);

  const handleSell = async () => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const baseUrl = isLocalhost ? '' : 'https://www.ankara-cagacrypto.com';
  
    if (!account || !library || !amount || isNaN(parseFloat(amount))) {
      setModalOpen(true);
      setModalStatus('done-fail');
      setModalTitle('Transaction Error');
      setModalSubtitle('Please connect your wallet and enter a valid amount.');
      return;
    }
  
    setIsBuying(true);
    setLoadingBalance(true);
    setModalOpen(true);
    setModalStatus('doing');
    setModalTitle('Processing Transaction');
    setModalSubtitle('Please wait...');
  
    try {
      const payload = {
        wallet: account,
        token: (currencies[Field.INPUT] as ExtendedCurrency)?.address || '',
        value: ethers.utils.parseEther(amount).toString(),
        sell: true
      };
  
      const response = await fetch(`${baseUrl}/dex/api/v1/transaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
      setIsBuying(false);
      setLoadingBalance(false);
  
      if (data.statusMsg === "200 OK") {
        setModalStatus('done-pass');
        setModalTitle('Transaction Completed');
        setModalSubtitle('Your transaction was successful!');
        setModalUrl(data.body.FinalTxToUser);
  
        setTimeout(() => {
          let attempts = 0;
          const intervalId = setInterval(async () => {
            await fetchBalance();
            if (ethBalanceRef.current !== ethBalance || attempts >= 10) {
              clearInterval(intervalId);
            }
            attempts++;
          }, 2000);
        }, 10000);
      } else {
        throw new Error(data.statusMsg || 'Transaction failed. Please try again.');
      }
    } catch (error) {
      console.error("Sell transaction failed: ", error);
      setIsBuying(false);
      setLoadingBalance(false);
      setModalStatus('done-fail');
      setModalTitle('Transaction Failed');
  
      // Handling unknown error type safely using type assertion
      const errorMessage = typeof error === 'string' ? error : (error as Error).message || 'An unexpected error occurred.';
      setModalSubtitle(errorMessage);
    }
  };
  
  

  useEffect(() => {
    // This will reset the amount when the symbol of the input currency changes
    onUserInput(Field.INPUT, '');
    setAmount('');
  }, [currencies[Field.INPUT]?.symbol]);

  const isDarkMode = useIsDarkMode();
  const borderStyle = isDarkMode ? '2px solid white' : '4px solid white';
  const dismissModal = () => {
    setModalOpen(false);
    // Additional cleanup or state updates as necessary
    // For example, resetting modal-related statuses if needed
    setIsBuying(false);
    setLoadingBalance(false);
    setModalStatus('doing'); // Clear status message
    setModalTitle('');
    setModalSubtitle('');
  };

  return (
    <>
      <MainContainer>
        <SideMenu />
        <ContentContainer>
          <AppBody>
            <SwapPoolTabs active='sell' />
            <Wrapper id="buy-page">
              <CurrencyInputPanel
                label="Amount to Sell"
                value={formattedAmounts[Field.INPUT]}
                onUserInput={handleTypeInput}
                onCurrencySelect={handleInputSelect}
                showMaxButton={true}
                onMax={handleMaxInput}
                currency={currencies[Field.INPUT]}
                id="send-currency-input"
              />

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '5px 1rem',
                position: 'relative',
              }}>
                <DownArrowIcon
                  strokeColor={theme.text1}
                  style={{
                    zIndex: '99',
                    width: '30px',
                    height: '30px',
                    backgroundColor: theme.fieldBg1,
                    border: borderStyle,
                    borderRadius: '40px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              </div>
              <BalanceDisplay>
                <Section>
                  {/* Left column for "You Receive" and its value */}
                  <Column>
                    <Info>You Receive</Info>
                    <BalanceValue>Sepolia Testnet</BalanceValue>
                  </Column>

                  {/* Right column for "Balance", ETH icon, and name, with adjustments for inline balance display */}
                  <Column>
                    {/* Using a flex container to keep "Balance:" and the value inline */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                      <Info>Balance:&nbsp;</Info>
                      <Value>{loadingBalance ? <SmallSpinner /> : `${ethBalance}`}</Value>
                    </div>
                    <CurrencyDisplay selected={true} style={{ alignSelf: 'flex-end', marginTop: '5px' }}>
                      <ButtonContainer selected={false}>
                        <StyledCurrencyIcon>
                          <img src={EthIcon} alt="Currency" />
                        </StyledCurrencyIcon>
                        <StyledTokenName>ETH</StyledTokenName>
                      </ButtonContainer>
                    </CurrencyDisplay>
                  </Column>
                </Section>
              </BalanceDisplay>



              <BottomGrouping>
                {!account ? (
                  // Show "Connect to Wallet" if no account is connected
                  <ButtonPrimary onClick={() => open()}>
                    Connect Wallet
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary
                    onClick={handleSell}
                    disabled={!account || !amount || isNaN(parseFloat(amount)) ||isBuying || loadingBalance || !isAllowedCurrency || !currencies[Field.INPUT]}>
                    {isBuying ? (
                      <Spinner />
                    ) : !currencies[Field.INPUT] ? (
                      'Select a Token for Sell'
                    ) : !isAllowedCurrency ? (
                      `Sell ${currencies[Field.INPUT]?.symbol || 'CAGA'} (Not Supported)`
                    ) : (
                      `Sell ${currencies[Field.INPUT]?.symbol || 'CAGA'}`
                    )}
                  </ButtonPrimary>

                )}
                {transactionStatus.message && (
                  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
                    <TYPE.body>
                      {transactionStatus.message} <br />
                      {transactionStatus.isSuccess && (
                        <a href={transactionStatus.link} target="_blank" rel="noopener noreferrer" style={{ color: theme.green1 }}>
                          {transactionStatus.linkText}
                        </a>
                      )}
                    </TYPE.body>
                  </div>
                )}
              </BottomGrouping>
            </Wrapper>
          </AppBody>
          <InformationSection trade={true} />
        </ContentContainer>
        {modalOpen && (
          <ModalTnx
            status={modalStatus}
            title={modalTitle}
            subtitle={modalSubtitle}
            onDismiss={dismissModal}
            url={modalUrl}
          />
        )}
      </MainContainer>
    </>
  );
}