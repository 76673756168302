import { Currency, Pair } from '@caga-cryptos/cagaswap-v2-sdk'
import React, { useState, useCallback } from 'react'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import { useTranslation } from 'react-i18next'
import {
  InputPanel,
  Container,
  LabelRow,
  InputRow,
  CurrencySelect,
  Aligner,
  StyledDropDown,
  StyledTokenName,
  StyledBalanceMax,
  SmallSpinner as Spinner
} from './styled'

// Extract `body` from `TYPE` and rename it to `Body`
const { body: Body } = TYPE;

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}

function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null,
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useWeb3ReactContext()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  function formatLargeNumbers(balance: any) {
    if (!balance) return ' -';  // Handle cases where there is no balance
    const number = parseFloat(balance);

    // Use standard formatting for numbers less than 1 million
    if (number < 1e6) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        useGrouping: false  // Ensure no grouping for numbers below 1 million
      }).format(number);
    }

    // Use compact notation for numbers greater than or equal to 1 million but less than a certain threshold
    if (number < 1e21) {
      return new Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2,
        useGrouping: false  // Ensure no grouping for compact notation
      }).format(number);
    }

    // Handle very large numbers using scientific notation without groupings
    return number.toExponential(2);
  }

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Body fontWeight={600} fontSize={14}>
                {label}
              </Body>
              {account && (
                <Body
                  onClick={onMax}
                  fontWeight={600}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency
                    ? (selectedCurrencyBalance
                      ? `Balance: ${formatLargeNumbers(selectedCurrencyBalance.toSignificant(6))}`
                      : <>Balance: <Spinner /></>)  // Use React Fragment <> to correctly render JSX
                    : 'Balance: -'}
                </Body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
              )}
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={20} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={'20px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                    '...' +
                    currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || t('selectToken') as string}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}

export default React.memo(CurrencyInputPanel);
