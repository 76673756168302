import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// Import SVGs
import BridgeIcon from "../../assets/images/navbar/bridge.svg";
import DexIcon from "../../assets/images/navbar/dex.svg";
import DexBlackIcon from "../../assets/images/navbar/dex_black.svg";
import ExplorerIcon from "../../assets/images/navbar/explorer.svg";
import ExplorerBlackIcon from "../../assets/images/navbar/explorer_black.svg";
import FaucetIcon from "../../assets/images/navbar/faucet.svg";
import GovernanceIcon from "../../assets/images/navbar/governance.svg";
import NftIcon from "../../assets/images/navbar/nft.svg";
import StakeIcon from "../../assets/images/navbar/stake.svg";
import SupportIcon from "../../assets/svg/support.svg";
import XIcon from "../../assets/svg/x.svg";
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import {
  ButtonPrimary,
} from "../../components/Button";
import { useWeb3Modal } from '@web3modal/ethers5/react';

// Styled Components
const DropdownContainer = styled.div`
  position: absolute;
  top: 75px;
  left: 2.5%;  // Centered by reducing width from 100% to 95%
  right: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: center;  // Aligns children (HeaderControls and Tabs2) at the center
  z-index: 2;
  width: 95%;  // Make width 95% of the parent
  background-color: ${({ theme }) => theme.grd1};
  height:100%!important
`;

const HeaderControlsContainer = styled.div<{ active: boolean }>`
  width: 94.5%;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 1rem;
  padding: 9.5px;
  padding-top:  ${({ active }) => active ? '20px !important' : '15px !important'};
  padding-bottom:${({ active }) => active ? '20px !important' : '15px !important'};
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);  // Optional: adds a subtle shadow for depth
  display: flex;
  justify-content: center;  // Center HeaderControls inside this container
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => active ? theme.bg3 : theme.bg1};
  border-radius: 12px;
`;

const Tabs2 = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 1rem;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 94.5%;
  margin-top: 20px;
`;
interface StyledNavLink2Props {
  isSelected: boolean;
}

const StyledNavLink2 = styled.a<StyledNavLink2Props>`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: start;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isSelected }) => isSelected ? '#FFFFFF !important' : theme.text1};  // Change text color to white for selected
  font-size: 15px;
  font-weight:  ${({ isSelected }) => isSelected ? 600 : 'normal'};  // Change font weight for selected
  margin: 3px 0px;
  border-radius: 5px;
  background-color: ${({ isSelected }) => isSelected ? 'transparent' : 'none'};
  background: ${({ isSelected }) => isSelected ? 'linear-gradient(90deg, #2606FF 0%, #3395EC 100%)' : 'none'};
  &:hover {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
  }
`;

const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const Toggle = styled.div`
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 3px;
  margin-top: 0.8rem;
  margin-right: 10px;
  margin-left: 1.2rem;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 9999px;
`;

const StyledHamburgerIcon = styled.svg`
  width: 20px;
  height: 20px;

  rect {
    fill: ${({ theme }) => theme.text1};
    height: 8px;
    width: 80;
    x: 10;
  }
`;

const MobileButtonPrimary = styled(ButtonPrimary)`
  width: 310px !important;  // Set width and use !important to ensure it is applied
  height: 40px !important;  // Set height and use !important to ensure it is applied
  font-weight: 700 !important;
`;

interface HamburgerIconProps {
  id: string;
  viewBox: string;
  ref: React.RefObject<SVGSVGElement>;
}

const HamburgerIcon = React.forwardRef<SVGSVGElement, HamburgerIconProps>(
  ({ id, viewBox }, ref) => (
    <StyledHamburgerIcon ref={ref} id={id} viewBox={viewBox}>
      <rect width='100' height='12'></rect>
      <rect y='24' width='100' height='12'></rect>
      <rect y='48' width='100' height='12'></rect>
    </StyledHamburgerIcon>
  )
);

interface Icons {
  bridge: string;
  dex: string;
  dexBlack: string;
  explorer: string;
  explorerBlack: string;
  faucet: string;
  governance: string;
  nft: string;
  stake: string;
  support: string;
}

const icons: Icons = {
  bridge: BridgeIcon,
  dex: DexIcon,
  dexBlack: DexBlackIcon,
  explorer: ExplorerIcon,
  explorerBlack: ExplorerBlackIcon,
  faucet: FaucetIcon,
  governance: GovernanceIcon,
  nft: NftIcon,
  stake: StakeIcon,
  support: SupportIcon
};

export function HeaderTabs() {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const hamburgerIconRef = useRef<SVGSVGElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { account } = useWeb3ReactContext();
  const { open } = useWeb3Modal();
  const location = useLocation();

  const getMenuLink = (baseLink: string) => {
    return account && baseLink ? `${baseLink}?iswalletconnected=true` : baseLink;
  };

  const menuItems = [
    { name: "Faucet", icon: icons.faucet, link: getMenuLink("https://portal-testnet.cagacrypto.com/faucet") },
    { name: "DEX", icon: icons.dex, link: "/#/swap", unselectedIcon: icons.dexBlack, paths: ['/swap', '/sell', '/send', '/pool', '/add'] },
    { name: "Explorer", icon: icons.explorer, link: "/#/explorer", unselectedIcon: icons.explorerBlack, paths: ['/explorer','/pairdetails'] },
    { name: "Bridge", icon: icons.bridge, link: getMenuLink("https://portal-testnet.cagacrypto.com/bridge") },
    { name: "NFT", icon: icons.nft, link: getMenuLink("https://portal-testnet.cagacrypto.com/nft") },
    { name: "Contact Support", icon: icons.support, link: "https://www.cagacrypto.com/contact-us/0" },
  ];

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const checkIsSelected = (path: string, paths: string[]) => {
    const basePath = path.split('?')[0];
    return paths.some(p => basePath.startsWith(p));
  };

  return (
    <>
      <Toggle onClick={toggleDropdown}>
        {!dropDownOpen ? (
          <HamburgerIcon ref={hamburgerIconRef} id='hamburgerIconId' viewBox='0 0 100 60' />
        ) : (
          <img src={XIcon} alt="Close icon" style={{ height: "16px", width: "16px" }} />
        )}
      </Toggle>
      {dropDownOpen && (
        <DropdownContainer ref={ref}>
          <HeaderControlsContainer onClick={(e) => e.stopPropagation()} active={!!account}>
            <HeaderControls>
              {!account ? (
                <MobileButtonPrimary onClick={() => open()}>
                  Connect Wallet
                </MobileButtonPrimary>
              ) : (
                <w3m-button balance="hide" />
              )}
            </HeaderControls>
          </HeaderControlsContainer>
          <Tabs2>
            {menuItems.map((item, index) => {
              const isSelected = checkIsSelected(location.pathname, item.paths || []);
              return (
                <StyledNavLink2
                  key={index}
                  href={item.link ? `${item.link}` : undefined}
                  isSelected={isSelected}
                  onClick={toggleDropdown}
                >
                  <IconWrapper>
                    <img
                      src={isSelected ? item.icon : item.unselectedIcon || item.icon}
                      alt={`${item.name} icon`}
                      style={{ height: "16px", width: "16px" }}
                    />
                  </IconWrapper>
                  {item.name}
                </StyledNavLink2>
              );
            })}
          </Tabs2>
        </DropdownContainer>
      )}
    </>
  );
}

export default HeaderTabs;