import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { TableContainer, StyledTable, Th, Td, PoolName, TooltipContainer, TooltipText, TokenLogoContainer, TokenLogo, SpinnerWrapper, SpinnerIcon } from './styled';
import caga from '../../assets/tokens/caga.png';
import cnt from '../../assets/tokens/cnt.png';
import doge from '../../assets/tokens/doge.svg';
import usdc from '../../assets/tokens/usdc.svg';
import usdt from '../../assets/tokens/usdt.svg';
import wbtc from '../../assets/tokens/wbtc.svg';

interface PoolMetric {
  id: string;
  pool_id: number;
  pool: string;
  transactions_count: number;
  tvl: number | string;
  day_volume: number | string;
  week_volume: number | string;
  day_apr: number | string;
}

const validTokens = ['CAGA', 'CTT', 'CNT', 'DOGE', 'USDT', 'USDC', 'WBTC'];

const tokenLogos: { [key: string]: string } = {
  CAGA: caga,
  CTT: caga, // CTT uses CAGA logo
  CNT: cnt,
  DOGE: doge,
  USDC: usdc,
  USDT: usdt,
  WBTC: wbtc,
  wCAGA: caga, // Map wCAGA to CAGA logo
};

const isValidPair = (pool: string) => {
  const [tokenA, tokenB] = pool.split('/');
  return validTokens.includes(tokenA.toUpperCase()) && validTokens.includes(tokenB.toUpperCase());
};

const formatTransactions = (count: number) => {
  if (count >= 1e9) {
    return `${(count / 1e9).toFixed(3)}B`;
  } else if (count >= 1e6) {
    return `${(count / 1e6).toFixed(3)}M`;
  } else if (count >= 1e3) {
    return `${(count / 1e3).toFixed(3)}K`;
  } else {
    return count.toString();
  }
};

const formatCurrency = (value: number) => {
  if (value >= 1e12) {
    return `$ ${(value / 1e12).toFixed(2)}T`;
  } else if (value >= 1e9) {
    return `$ ${(value / 1e9).toFixed(2)}B`;
  } else if (value >= 1e6) {
    return `$ ${(value / 1e6).toFixed(2)}M`;
  } else if (value >= 1e3) {
    return `$ ${(value / 1e3).toFixed(2)}K`;
  } else {
    return `$ ${value.toFixed(2)}`;
  }
};

interface PoolListProps {
  searchQuery: string;
}

const PoolList: React.FC<PoolListProps> = ({ searchQuery }) => {
  const [poolMetrics, setPoolMetrics] = useState<PoolMetric[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortColumn, setSortColumn] = useState<string>('tvl');
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://dex-info-testnet.cagacrypto.com/api/poolmetrics');
        const sortedData = response.data.sort((a: PoolMetric, b: PoolMetric) => {
          const valueA = typeof a[sortColumn] === 'number' ? a[sortColumn] : parseFloat(a[sortColumn]);
          const valueB = typeof b[sortColumn] === 'number' ? b[sortColumn] : parseFloat(b[sortColumn]);
          return sortOrder === 'desc' ? valueB - valueA : valueA - valueB;
        });
        setPoolMetrics(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [sortColumn, sortOrder]);

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
    const sortedData = [...poolMetrics].sort((a, b) => {
      const valueA = typeof a[column] === 'number' ? a[column] : parseFloat(a[column]);
      const valueB = typeof b[column] === 'number' ? b[column] : parseFloat(b[column]);
      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    });
    setPoolMetrics(sortedData);
  };

  const replaceToken = (pool: string) => {
    return pool.replace(/wCAGA/i, 'CAGA');
  };

  const getTokenLogos = (pool: string) => {
    const [tokenA, tokenB] = pool.split('/');
    const tokenALogo = tokenLogos[tokenA.toUpperCase()];
    const tokenBLogo = tokenLogos[tokenB.toUpperCase()];

    return (
      <TokenLogoContainer>
        <TokenLogo src={tokenALogo} alt={tokenA} />
        <TokenLogo src={tokenBLogo} alt={tokenB} />
      </TokenLogoContainer>
    );
  };

  const handleRowClick = (poolId: number, tokenA: string, tokenB: string) => {
    history.push(`/pairdetails/${poolId}?tokenA=${tokenA}&tokenB=${tokenB}`);
  };
  
  const filteredPools = poolMetrics
    .filter(metric => isValidPair(replaceToken(metric.pool)))
    .filter(metric => replaceToken(metric.pool).toLowerCase().includes(searchQuery.toLowerCase()));
  
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <Th>#</Th>
            <Th style={{ textAlign: 'left' }}>Pool</Th>
            <Th onClick={() => handleSort('transactions_count')}>
              Transactions {sortColumn === 'transactions_count' ? (sortOrder === 'desc' ? '↓' : '↑') : ''}
            </Th>
            <Th onClick={() => handleSort('tvl')}>
              TVL {sortColumn === 'tvl' ? (sortOrder === 'desc' ? '↓' : '↑') : ''}
            </Th>
            <Th onClick={() => handleSort('day_volume')}>
              1 Day Volume {sortColumn === 'day_volume' ? (sortOrder === 'desc' ? '↓' : '↑') : ''}
            </Th>
            <Th onClick={() => handleSort('week_volume')}>
              7 Day Volume {sortColumn === 'week_volume' ? (sortOrder === 'desc' ? '↓' : '↑') : ''}
            </Th>
            <Th onClick={() => handleSort('day_apr')}>
              1 Day APR {sortColumn === 'day_apr' ? (sortOrder === 'desc' ? '↓' : '↑') : ''}
            </Th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={7}>
                <SpinnerWrapper>
                  <SpinnerIcon />
                </SpinnerWrapper>
              </Td>
            </tr>
          ) : (
            filteredPools
              .sort((a, b) => {
                const valueA = typeof a[sortColumn] === 'number' ? a[sortColumn] : parseFloat(a[sortColumn]);
                const valueB = typeof b[sortColumn] === 'number' ? b[sortColumn] : parseFloat(b[sortColumn]);
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
              })
              .map((metric, index) => {
                const [tokenA, tokenB] = replaceToken(metric.pool).split('/');
                return (
                  <tr key={metric.id} onClick={() => handleRowClick(metric.pool_id, tokenA, tokenB)}>
                    <Td>{index + 1}</Td>
                    <PoolName>
                      {getTokenLogos(replaceToken(metric.pool))}
                      <span style={{ marginLeft: '20px' }}>{replaceToken(metric.pool)}</span>
                    </PoolName>
                    <Td style={{ fontWeight: 600 }}>{formatTransactions(metric.transactions_count)}</Td>
                    <Td style={{ fontWeight: 600 }}>{formatCurrency(Number(metric.tvl))}</Td>
                    <Td style={{ fontWeight: 600 }}>{formatCurrency(Number(metric.day_volume))}</Td>
                    <Td style={{ fontWeight: 600 }}>{formatCurrency(Number(metric.week_volume))}</Td>
                    <Td style={{ fontWeight: 600 }}>
                      {metric.day_apr === '0' ? '0%' : (
                        <TooltipContainer>
                          {`${parseFloat(metric.day_apr as string).toFixed(3)}%`}
                          <TooltipText className="tooltip-text">{metric.day_apr}</TooltipText>
                        </TooltipContainer>
                      )}
                    </Td>
                  </tr>
                );
              })
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default PoolList;