import styled from 'styled-components';
import { darken } from 'polished';
import { NavLink } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

const activeClassName = 'ACTIVE';

export const TabsWrapper = styled.div`
  position: relative;
  padding-bottom: 1px;
  margin-bottom: 32px;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align tabs to the left */
`;

export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px; /* Adjust padding as needed */
  height: 3rem;
  margin-right: 8px; /* Spacing between tabs */
  text-decoration: none;
  color: ${({ theme }) => theme.text3}; /* Default text color */
  font-size: 20px;
  font-weight: bold; /* Ensures text is always bold */

  &:last-child {
    margin-right: 0; /* No right margin on the last tab */
  }

  &.${activeClassName} {
    background-image: ${({ theme }) => theme.primaryGradient5};
    color: transparent; /* Ensures the text color is transparent */
    background-clip: text;
    -webkit-background-clip: text; /* For WebKit browsers */
    -webkit-text-fill-color: transparent; /* Specifically for Safari */
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => darken(0.1, theme.text1)}; /* Slightly darken text on hover/focus */
  }
`;

export const Underline = styled.div`
  height: 2px;
  background: #F5F5F5;
  width: 98%;
  margin-left: 3px;
  position: absolute;
  bottom: 0; /* Align at the bottom of the TabsWrapper */
  border-radius: 10px; /* Adds rounded corners */
`;

export const ActiveIndicator = styled.div<{ left: number; width: number }>`
  height: 4px;
  background: ${({ theme }) => theme.primaryGradient4};
  position: absolute;
  bottom: 0; /* Align with the bottom of the Tabs container */
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  /* transition: left 0.3s ease-out, width 0.3s ease-out; Add transitions for left and width */
  border-radius: 4px; /* Adds rounded corners */
`;

export const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`;

export const SettingsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
