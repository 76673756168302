import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TableContainer, StyledTable, Th, Td, PoolName, TokenLogoContainer, TokenLogo, SpinnerWrapper, SpinnerIcon } from './styled';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

import caga from '../../assets/tokens/caga.png';
import cnt from '../../assets/tokens/cnt.png';
import doge from '../../assets/tokens/doge.svg';
import usdc from '../../assets/tokens/usdc.svg';
import usdt from '../../assets/tokens/usdt.svg';
import wbtc from '../../assets/tokens/wbtc.svg';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const tokenLogos: { [key: string]: string } = {
  CAGA: caga,
  CTT: caga, // CTT uses CAGA logo
  CNT: cnt,
  DOGE: doge,
  USDC: usdc,
  USDT: usdt,
  WBTC: wbtc,
};

const validTokens = ['CAGA', 'CTT', 'CNT', 'DOGE', 'USDT', 'USDC', 'WBTC'];

const noDataChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'No Data',
      font: { size: 16 },
    },
  },
  scales: {
    x: { display: false },
    y: { display: false },
  },
  elements: {
    line: { borderWidth: 1 }
  },
};

const noDataChartData = {
  labels: [''],
  datasets: [
    {
      data: [0],
      borderColor: 'gray',
      fill: false,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 1,
    },
  ],
};

const formatNumber = (num: number) => {
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(1)}B`;
  } else if (num >= 1e6) {
    return `${(num / 1e6).toFixed(1)}M`;
  } else if (num >= 1e3) {
    return `${(num / 1e3).toFixed(1)}K`;
  } else {
    return num.toFixed(1);
  }
};

interface TokenListProps {
  volumePeriod: string;
  searchQuery: string;
}

const TokenList: React.FC<TokenListProps> = ({ volumePeriod, searchQuery }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/markets',
          {
            headers: {
              'x-cg-demo-api-key': 'CG-3h9pBrrjJRjowKnkoSK8Mzeb'
            },
            params: {
              vs_currency: 'usd',
              ids: 'caga,tether,usd-coin,dogecoin,wrapped-bitcoin',
              order: 'market_cap_desc',
              per_page: 100,
              page: 1,
              sparkline: true,
              price_change_percentage: '1h,24h'
            }
          }
        );
        const extraToken = {
          id: 'caga',
          name: 'CAGA',
          symbol: 'CAGA',
          current_price: 0.00035,
          price_change_percentage_1h_in_currency: null,
          price_change_percentage_24h_in_currency: null,
          fully_diluted_valuation: null,
          total_volume: null,
          sparkline_in_7d: { price: [] }
        };
        setData([extraToken, ...response.data]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [volumePeriod]);

  const filteredData = data.filter(token =>
    token.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <Th>#</Th>
            <Th>Token Name</Th>
            <Th>Price</Th>
            <Th>1 Hour</Th>
            <Th>1 Day</Th>
            <Th>FDV</Th>
            <Th>Volume</Th>
            <Th>&nbsp;</Th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={8}>
                <SpinnerWrapper>
                  <SpinnerIcon />
                </SpinnerWrapper>
              </Td>
            </tr>
          ) : (
            filteredData.map((token, index) => (
              <tr key={token.id}>
                <Td>{index + 1}</Td>
                <Td>
                  <TokenLogoContainer>
                    <TokenLogo src={tokenLogos[token.symbol.toUpperCase()]} alt={token.name} width={20} height={20} />
                    {token.name}
                  </TokenLogoContainer>
                </Td>
                <Td style={{ fontWeight: 600 }}>${token.current_price.toFixed(5)}</Td>
                <Td
                  style={{
                    color: token.price_change_percentage_1h_in_currency > 0 ? 'green' : 'red',
                    fontWeight: 600
                  }}
                >
                  {token.price_change_percentage_1h_in_currency?.toFixed(2) || '-'}%
                </Td>
                <Td
                  style={{
                    color: token.price_change_percentage_24h_in_currency > 0 ? 'green' : 'red',
                    fontWeight: 600
                  }}
                >
                  {token.price_change_percentage_24h_in_currency?.toFixed(2) || '-'}%
                </Td>
                <Td style={{ fontWeight: 600 }}>
                  {token.fully_diluted_valuation ? `$${formatNumber(token.fully_diluted_valuation)}` : '-'}
                </Td>
                <Td style={{ fontWeight: 600 }}>
                  {token.total_volume ? formatNumber(token.total_volume) : '-'}
                </Td>
                <Td>
                  {token.sparkline_in_7d.price.length > 0 ? (
                    <Line
                      data={{
                        labels: token.sparkline_in_7d.price.map((_: number, i: number) => i),
                        datasets: [
                          {
                            data: token.sparkline_in_7d.price,
                            borderColor: token.sparkline_in_7d.price[0] > token.sparkline_in_7d.price[token.sparkline_in_7d.price.length - 1] ? 'red' : 'green',
                            fill: false,
                            tension: 0.4,
                            pointRadius: 0,
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            display: false,
                          },
                          y: {
                            display: false,
                          },
                        },
                        elements: {
                          line: {
                            borderWidth: 1,
                          },
                        },
                      }}
                      width={120}
                      height={50}
                    />
                  ) : (
                    <Line data={noDataChartData} options={noDataChartOptions} width={120} height={50} />
                  )}
                </Td>
              </tr>
            ))
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default TokenList;