import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ethers } from 'ethers';
import {
    MainContainer,
    ContentContainer,
} from '../LayoutStyles'; // Import the layout styles
import {
    PoolDetailsContainer, // Use a new styled container for PoolDetails
    TableContainer,
    StyledTable,
    Th,
    Td,
    BodyWrapper,
    ChartContainer,
    Card,
    CardBody,
    CustomLabel,
    ChartWrapper,
    BottomLayout,
    PoolDetailsLeftLayout,
    PoolDetailsRightLayout,
    SpinnerWrapper,
    SpinnerIcon,
    ButtonRow,
    StatCard,
    StatRow,
    StatLabel,
    StatValue,
    BarContainer,
    Bar,
    LinkRow,
    TokenLogoContainer,
    TokenLogo,
    LinksContainer
} from './styled';
import SideMenu from '../../components/SideMenu';
import caga from '../../assets/tokens/caga.png';
import cnt from '../../assets/tokens/cnt.png';
import doge from '../../assets/tokens/doge.svg';
import usdc from '../../assets/tokens/usdc.svg';
import usdt from '../../assets/tokens/usdt.svg';
import wbtc from '../../assets/tokens/wbtc.svg';

const tokenLogos = {
    CAGA: caga,
    CTT: caga,
    CNT: cnt,
    DOGE: doge,
    USDC: usdc,
    USDT: usdt,
    WBTC: wbtc,
    wCAGA: caga,
};

const formatTimeAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) return `${interval}y ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) return `${interval}mo ago`;
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `${interval}d ago`;
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval}h ago`;
    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval}m ago`;
    return `${Math.floor(seconds)}s ago`;
};

const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            alert('Address copied to clipboard!');
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
};

const PairDetails: React.FC = () => {
    const { pool_id } = useParams<{ pool_id: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tokenAName = queryParams.get('tokenA');
    const tokenBName = queryParams.get('tokenB');
    const [pairAddress, setPairAddress] = useState<string | null>(null);
    const [tokenA, setTokenA] = useState<string | null>(null);
    const [tokenB, setTokenB] = useState<string | null>(null);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPairAddress = async () => {
            try {
                console.log(`Fetching pair address for pool_id: ${pool_id}`);
                const response = await axios.get(`https://dex-info-testnet.cagacrypto.com/api/pooladdress/${pool_id}`);
                const data = response.data;
                setPairAddress(data.pool_address);
                setTokenA(data.token0);
                setTokenB(data.token1);
            } catch (error) {
                console.error('Error fetching pair address:', error);
            }
        };

        fetchPairAddress();
    }, [pool_id, tokenAName, tokenBName]);



    const tokenABalance = ethers.utils.parseEther('100'); // 1000 tokens
    const tokenBBalance = ethers.utils.parseEther('500'); // 5000 tokens
    useEffect(() => {
        if (pairAddress) {
            const fetchTransactions = async () => {
                try {
                    const start = Date.now();
                    const response = await axios.get(`https://dex-info-testnet.cagacrypto.com/api/lasttransactions/${pairAddress}`);
                    const data = response.data;
                    setTransactions(data);
                    const end = Date.now();
                    console.log(`Time taken to retrieve data: ${end - start} ms`);
                } catch (error) {
                    console.error('Error fetching transactions:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchTransactions();
        }
    }, [pairAddress]);

    const formatAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

    const formatAmount = (amount: string) => {
        const parsedAmount = parseFloat(ethers.utils.formatEther(amount));
        return parsedAmount.toFixed(2);
    };

    const convertToLocalTime = (timestamp: string) => {
        const utcDate = new Date(timestamp + ' UTC'); // Add 'UTC' to indicate the time is in UTC
        return formatTimeAgo(utcDate);
    };
    return (
        <MainContainer>
            <SideMenu />
            <ContentContainer>
                <BodyWrapper animate={true}>
                    <PoolDetailsContainer>
                        <h1>Pair Details</h1>
                        <p>Explorer {'>'} Pools {'>'} {tokenAName} / {tokenBName} ({formatAddress(pairAddress ?? '')})</p>
                        <BottomLayout>
                            <PoolDetailsLeftLayout>
                                <ChartContainer>
                                    <Card>
                                        <CardBody>
                                            <CustomLabel>
                                                <span>Chart</span>
                                            </CustomLabel>
                                            <ChartWrapper>
                                                {/* Placeholder for Chart */}
                                            </ChartWrapper>
                                        </CardBody>
                                    </Card>
                                </ChartContainer>
                                <h2>Transactions</h2>
                                <TableContainer>
                                    <StyledTable>
                                        <thead>
                                            <tr>
                                                <Th>Time</Th>
                                                <Th>Type</Th>
                                                <Th>{tokenAName} Amount</Th>
                                                <Th>{tokenBName} Amount</Th>
                                                <Th>Wallet (to)</Th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <Td colSpan={5}>
                                                        <SpinnerWrapper>
                                                            <SpinnerIcon />
                                                        </SpinnerWrapper>
                                                    </Td>
                                                </tr>
                                            ) : transactions.length > 0 ? (
                                                transactions.map((tx, index) => (
                                                    <tr key={index}>
                                                        <Td>{convertToLocalTime(tx.timestamp)}</Td>
                                                        <Td style={{ color: parseFloat(tx.amount0In) > 0 ? 'red' : 'green' }}>
                                                            {parseFloat(tx.amount0In) > 0 ? `Sell ${tokenAName}` : `Buy ${tokenAName}`}
                                                        </Td>
                                                        <Td>{formatAmount(parseFloat(tx.amount0In) > 0 ? tx.amount0In : tx.amount0Out)}</Td>
                                                        <Td>{formatAmount(parseFloat(tx.amount1In) > 0 ? tx.amount1In : tx.amount1Out)}</Td>
                                                        <Td>{formatAddress(tx.to)}</Td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <Td colSpan={5}>No transactions found.</Td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </StyledTable>
                                </TableContainer>
                            </PoolDetailsLeftLayout>
                            <PoolDetailsRightLayout>
                                <ButtonRow>
                                    <button>Swap</button>
                                    <button>Add Liquidity</button>
                                </ButtonRow>
                                <StatCard>
                                    <h3>Stats</h3>
                                    <StatRow>
                                        <StatLabel>Pool balances</StatLabel>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                            <StatLabel>
                                                {formatAmount(tokenABalance.toString())}
                                                <TokenLogo src={tokenLogos[tokenAName ?? ''] || caga} alt={tokenAName ?? 'TokenA'} style={{ marginLeft: "3px" }} />
                                                {tokenAName}
                                            </StatLabel>
                                            <StatLabel>
                                                {formatAmount(tokenBBalance.toString())}
                                                <TokenLogo src={tokenLogos[tokenBName ?? ''] || caga} alt={tokenBName ?? 'TokenB'} style={{ marginLeft: "3px" }} />
                                                {tokenBName}
                                            </StatLabel>
                                        </div>
                                        <BarContainer>
                                            <Bar percentage={20} color="orange" />
                                            <Bar percentage={80} color="blue" />
                                        </BarContainer>
                                    </StatRow>
                                    <StatRow>
                                        <StatLabel>TVL</StatLabel>
                                        <StatValue>$xxxx.xM</StatValue>
                                    </StatRow>
                                    <StatRow>
                                        <StatLabel>24H volume</StatLabel>
                                        <StatValue>$xxx.xM</StatValue>
                                    </StatRow>
                                    <StatRow>
                                        <StatLabel>24h Fee</StatLabel>
                                        <StatValue>xxx.xK</StatValue>
                                    </StatRow>
                                </StatCard>



                                <LinksContainer>
                                    <h3>Links</h3>
                                    <LinkRow>
                                        <TokenLogoContainer>
                                            <TokenLogo src={tokenLogos[tokenAName ?? ''] || caga} alt={tokenA ?? 'TokenA'} />
                                            <TokenLogo src={tokenLogos[tokenBName ?? ''] || caga} alt={tokenB ?? 'TokenB'} />
                                        </TokenLogoContainer>
                                        <a onClick={() => handleCopy(pairAddress ?? '')}>
                                            {formatAddress(pairAddress ?? '')} <img src={caga} alt="Copy to Clipboard" />
                                        </a>
                                    </LinkRow>
                                    <LinkRow>
                                        <TokenLogoContainer style={{ paddingLeft: '15px' }}>
                                            <TokenLogo src={tokenLogos[tokenAName ?? ''] || caga} alt={tokenA ?? 'TokenA'} />
                                        </TokenLogoContainer>
                                        <a onClick={() => handleCopy(tokenA ?? '')}>
                                            {formatAddress(tokenA ?? '')} <img src={caga} alt="Copy to Clipboard" />
                                        </a>
                                    </LinkRow>
                                    <LinkRow>
                                        <TokenLogoContainer style={{ paddingLeft: '15px' }}>
                                            <TokenLogo src={tokenLogos[tokenBName ?? ''] || caga} alt={tokenB ?? 'TokenB'} />
                                        </TokenLogoContainer>
                                        <a onClick={() => handleCopy(tokenB ?? '')}>
                                            {formatAddress(tokenB ?? '')} <img src={caga} alt="Copy to Clipboard" />
                                        </a>
                                    </LinkRow>
                                </LinksContainer>

                            </PoolDetailsRightLayout>
                        </BottomLayout>
                    </PoolDetailsContainer>
                </BodyWrapper>
            </ContentContainer>
        </MainContainer>
    );
};

export default PairDetails;