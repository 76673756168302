import React, { useEffect, useState, useContext } from 'react';
import { AutoColumn } from '../Column';
import { TYPE, Spinner } from '../../theme';
import { RowBetween } from '../Row';
import { ThemeContext } from 'styled-components';
import { ethers } from 'ethers';
import styled, { keyframes } from 'styled-components';

interface SepoliaSwapDetailsProps {
  buyToken: string;
  sellAmount: string;
}

interface SwapApiResponse {
  estimatedPriceImpact: number;
  protocolFee: string;
  price: string;
  buyTokenAddress: string;
  buyAmount: string;
}

const TOKEN_ADDRESSES: { [address: string]: string } = {
  '0xa4eE9C98643403cF6555728272129c721EF4c266': 'USDC',
  '0x3637925eE8B837f85c7309e4b291Ca56A40457a4': 'USDT',
  '0xE1eC108ba9fB9B8A691848d9C5A34aA5fBaA6e23': 'DOGE',
  '0x835EF3b3D6fB94B98bf0A3F5390668e4B83731c5': 'WBTC',
  '0x2514c246226EEE6EF9F3aB017Be2c01FAa3312dD': 'CTT'
};

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const CenteredSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;  // Adjust height as necessary

  > div {
    width: 48px;
    height: 48px;
    border: 4px solid #BFCAFF;
    border-top-color: #3253FF;
    border-radius: 50%;
    animation: ${spinAnimation} 1s linear infinite;
  }
`;

function SepoliaAdvancedSwapDetails({ buyToken, sellAmount }: SepoliaSwapDetailsProps) {
  const [swapDetails, setSwapDetails] = useState<SwapApiResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (!sellAmount || parseFloat(sellAmount) === 0) {
      console.error('Sell amount is zero or undefined.');
      return; // Exit early if sellAmount is 0 or not provided
    }
  
    // Check if the sellAmount is just a "."
    if (sellAmount.trim() === ".") {
      console.error('Invalid sell amount: Just a decimal point is not allowed.');
      return; // Exit early if sellAmount is just a decimal point
    }
  
    const amount = parseFloat(sellAmount);
    if (amount === 0) {
      console.error('Invalid sell amount: Amount is zero.');
      return; // Exit early if parsed amount is 0
    }
    
    const fetchSwapDetails = async () => {
      setLoading(true);
      const sellAmountInWei = ethers.utils.parseUnits(sellAmount, 'ether').toString();
      const url = `https://sepolia.api.0x.org/swap/v1/quote?sellToken=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&buyToken=${buyToken}&sellAmount=${sellAmountInWei}`;


      try {
        const response = await fetch(url, {
          headers: { '0x-api-key': 'd5d1fd2a-774d-4655-bfa6-9900840dc86e' }
        });
        const data = await response.json();
        if (response.ok) {
          setSwapDetails(data);
        } else {
          console.error('API error:', data);
          setSwapDetails(null);
        }
      } catch (error) {
        console.error('Failed to fetch swap details:', error);
        setSwapDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSwapDetails();
  }, [buyToken, sellAmount]);

  if (loading) {
    return (
      <CenteredSpinner>
        <div /> {/* This is the Spinner element */}
      </CenteredSpinner>
    );
  }

  if (!swapDetails) {
    return <TYPE.body color={theme.red1}>Failed to load swap details</TYPE.body>;
  }

  const tokenSymbol = TOKEN_ADDRESSES[buyToken] || 'Unknown Token';
  const formattedPrice = parseFloat(swapDetails.price).toFixed(2); // Format price to 2 decimal places

  const slippageTolerance = 0.01;  // 1% slippage tolerance


const buyAmountInDecimal = ethers.utils.formatUnits(swapDetails.buyAmount, 'ether');  
const minimumReceived = (parseFloat(buyAmountInDecimal) * (1 - slippageTolerance)).toFixed(2);
  return (
    <AutoColumn gap="md" style={{ padding: '20px' }}>
      <RowBetween>
        <TYPE.black color={theme.text1} fontWeight={500}>
          Minimum received:
        </TYPE.black>
        <TYPE.black color={theme.text1} fontWeight={500}>
          {minimumReceived } {tokenSymbol}
        </TYPE.black>
      </RowBetween>
      <RowBetween>
        <TYPE.black>Price Impact:</TYPE.black>
        <TYPE.black>{(swapDetails.estimatedPriceImpact * 100).toFixed(2)}%</TYPE.black>
      </RowBetween>
      <RowBetween>
        <TYPE.black>Liquidity Provider Fee:</TYPE.black>
        <TYPE.black>{swapDetails.protocolFee} ETH</TYPE.black>
      </RowBetween>
    </AutoColumn>
  );
}

export default SepoliaAdvancedSwapDetails;
