import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Token, TokenAmount } from '@caga-cryptos/cagaswap-v2-sdk';
import { useWeb3ReactContext } from '../contexts/Web3Context';
import { ERC20_ABI } from '../constants/abis/erc20';

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const { library } = useWeb3ReactContext();
  const [allowance, setAllowance] = useState<TokenAmount | undefined>();

  useEffect(() => {
    if (!library || !token || !owner || !spender) {
      setAllowance(undefined);
      return;
    }

    const fetchAllowance = async () => {
      const tokenContract = new ethers.Contract(token.address, ERC20_ABI, library);
      try {
        const allowanceAmount = await tokenContract.allowance(owner, spender);
        const tokenAllowance = new TokenAmount(token, allowanceAmount.toString());
        setAllowance(tokenAllowance);
      } catch (error) {
        console.error('Failed to fetch allowance:', error);
        setAllowance(undefined);
      }
    };

    fetchAllowance();
  }, [library, token, owner, spender]);

  return allowance;
}
