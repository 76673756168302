import React, { useEffect } from 'react';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalTheme } from '@web3modal/ethers5/react';
import styled from 'styled-components';
import WalletIcon from '../../assets/svg/wallet.png'; // Corrected import path
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import { useDarkModeManager } from "../../state/user/hooks";

// Define the CAGA Ankara Testnet configuration
const cagaAnkaraTestnet = {
  chainId: 72778,
  name: 'CAGA Ankara Testnet',
  currency: 'CAGA',
  explorerUrl: 'https://ankara-cagacrypto.tryethernal.com',
  rpcUrl: 'https://www.ankara-cagacrypto.com'
};

// Metadata for the Web3Modal instance
const metadata = {
  name: 'CAGA Portal - DEX',
  description: 'Decentralized exchange on the CAGA network',
  url: 'https://dex-testnet.cagacrypto.com/',
  icons: ['https://dex-testnet.cagacrypto.com/favicon.ico']
};

// Ethers configuration
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: cagaAnkaraTestnet.rpcUrl,
  defaultChainId: cagaAnkaraTestnet.chainId
});

// Create the Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: [cagaAnkaraTestnet],
  projectId: '966941be00b485cc881c9e7c9d11f306',
  enableAnalytics: true,
  enableOnramp: true,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  includeWalletIds: [
    "f5b4eeb6015d66be3f5940a895cbaa49ef3439e518cd771270e6b553b48f31d2",
    "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef",
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662",
    "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
    "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
    "c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2",
    "fb6ed96272ec885008e896c6146002048d8dc88c0b7e0e6fa42bcadf052a1569",
  ],
  customWallets: [
    {
      id: "Fearless Wallet",
      name: "Fearless Wallet",
      homepage: "https://fearlesswallet.io/", // Optional
      image_url:
        "https://lh3.googleusercontent.com/XWkzC6svv2OLL365sMlTpcFBQsz4RLD0gnFFMDp3Y6fKiIFdrI8dd1-64zOCi4dWc__GYxTbmLGGksQT1uYtShN15w=s60", // Optional
      mobile_link:
        "https://chromewebstore.google.com/detail/fearless-wallet/nhlnehondigmgckngjomcpcefcdplmgc", // Optional - Deeplink or universal
      desktop_link:
        "https://chromewebstore.google.com/detail/fearless-wallet/nhlnehondigmgckngjomcpcefcdplmgc", // Optional - Deeplink
      webapp_link:
        "https://chromewebstore.google.com/detail/fearless-wallet/nhlnehondigmgckngjomcpcefcdplmgc", // Optional
      app_store: "https://apps.apple.com/us/app/fearless-wallet/id1537251089", // Optional
      play_store:
        "https://play.google.com/store/apps/details?id=jp.co.soramitsu.fearless", // Optional
    },
  ]
});

const ConnectButton = styled.button`
  background-image: linear-gradient(to right, #2504FF, #3398EB);
  color: white;
  font-size: 14px;
  padding: 2px 12px;
  font-weight: bold;
  width: 176px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  border: 0;

  img {
    margin-right: 8px;
    margin-bottom: 4px;
  }
`;

export default function Web3ModalComponent() {
  const { setThemeMode } = useWeb3ModalTheme();
  const { open } = useWeb3Modal()
  const { account } = useWeb3ReactContext();
  const [isDark] = useDarkModeManager();

  useEffect(() => {
    setThemeMode(isDark ? 'dark' : 'light');  // Set the theme mode to light on component mount
  }, [setThemeMode]);

  return (
    <div>
      {!account ? (
        <ConnectButton onClick={() => open()}>
          <img src={WalletIcon} alt="" width="16" height="16" />
          Connect Wallet
        </ConnectButton>
      ) : (
        <w3m-button />
      )}
    </div>
  );
}
