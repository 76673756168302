import React from 'react';
import styled, { keyframes } from 'styled-components';
import Info from '../../assets/images/info.png'; // Image import

// Define the fade-up keyframe animation
const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Define TypeScript interface for the props
interface InformationSectionProps {
  trade?: boolean; // 'trade' prop to control margin-top of AppBody
}

// Styled component for the app container
const AppBody = styled.div<{ trade: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  width: 100%;
  margin-top: ${({ trade }) => (trade ? '20px' : '-15px')};
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01),
              0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 16px 24px rgba(0, 0, 0, 0.04),
              0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  padding: 0.2rem;
  animation: ${fadeUp} 0.5s ease-out; /* Apply the fade-up animation */
`;

// Wrapper for layout
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 20px;
  gap: 20px;
`;

// Columns for content organization
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// Left column settings
const LeftColumn = styled(Column)`
  flex: 1 0 55%;
`;

// Right column settings
const RightColumn = styled(Column)`
  flex: 1 0 45%;
  align-items: center;
`;

// Rows for text content
const Row = styled.div`
  margin-bottom: 5px;
`;

// Title styling
const Title = styled.h2`
  font-weight: 700;
  font-size: 14px;
  color:  ${({ theme }) => theme.text1};
`;

// Subtitle styling with gradient
const Subtitle = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(90deg, #2400FF 0%, #339DE9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  text-decoration: none; // Removes underline typically added to links
`;

// Image placeholder styled component
const ImagePlaceholder = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 16px;
  background-image: url(${Info});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

// Functional component with props
const InformationSection: React.FC<InformationSectionProps> = ({ trade = false }) => {
  return (
    <AppBody trade={trade}>
      <Wrapper>
        <LeftColumn>
          <Row>
            <Title>
            By using CAGA to pay for transactions across various networks, you can enjoy reduced gas fees.
            </Title>
          </Row>
          <Row>
            <Subtitle href="https://cagacrypto.gitbook.io/wp/2024-v1/caga-dex" target="_blank">
              Find out now
            </Subtitle>
          </Row>
        </LeftColumn>
        <RightColumn>
          <ImagePlaceholder />
        </RightColumn>
      </Wrapper>
    </AppBody>
  );
};

export default InformationSection;
