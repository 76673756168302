import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Dropdown, DropdownItem } from 'reactstrap';
import TokenList from './TokenList';
import PoolList from './PoolList';
import TransactionsList from './TransactionsList'; // Import the TransactionsList component
import 'bootstrap/dist/css/bootstrap.min.css';
import { MainContainer, ContentContainer } from '../LayoutStyles';
import SideMenu from '../../components/SideMenu';
import {
  ChartContainer,
  ChartWrapper,
  BottomLayout,
  LeftLayout,
  BodyWrapper,
  ExploreContainer,
  NavWrapper,
  TabsWrapper,
  Tabs,
  StyledNavLink,
  Underline,
  ActiveIndicator,
  FiltersContainer,
  SearchInput,
  Card,
  CardBody,
  CustomLabel
} from './styled';
import cagaLogo from '../../assets/svg/caga.svg'; // Import your logo
import { DropdownMenuStyled, DropdownToggleStyled, VolumeDropdownMenuStyled } from './styled'; // Ensure to import your styled components

// Register necessary components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default function Explorer() {
  const [activeTab, setActiveTab] = useState<'tokens' | 'pools' | 'transactions'>('pools');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [volumeDropdownOpen, setVolumeDropdownOpen] = useState(false);
  const [selectedVolume, setSelectedVolume] = useState('1H Volume');
  const [indicatorStyle, setIndicatorStyle] = useState<{ left: number; width: number }>({ left: 0, width: 0 });
  const [searchQuery, setSearchQuery] = useState('');
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.querySelector(`.tab-${activeTab}`) as HTMLElement;
      if (activeTabElement) {
        setIndicatorStyle({
          left: activeTabElement.offsetLeft,
          width: activeTabElement.offsetWidth,
        });
      }
    }
  }, [activeTab]);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const toggleVolumeDropdown = () => setVolumeDropdownOpen(prevState => !prevState);

  const handleTabSelect = (tab: 'tokens' | 'pools' | 'transactions') => {
    setActiveTab(tab);
    setSearchQuery(''); // Clear the search query when the tab is changed
  };

  const handleVolumeSelect = (volume: string) => {
    setSelectedVolume(volume);
    setVolumeDropdownOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const MockLineChartData = useMemo(() => ({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Line Dataset',
        data: [40, 45, 60, 81, 89, 55, 50],
        fill: true,
        backgroundColor: 'rgba(22, 119, 255, 0.1)',
        borderColor: '#1677FF',
      },
    ],
  }), []);
  

  const chartOptions = useMemo(() => ({
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        }
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Remove y-axis tick labels
        },
        border: {
          display: false,  // Hide the y-axis line
        },
      },
    },
  }), []);
  
  const MockBarChartData = useMemo(() => ({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Bar Dataset',
        data: [10, 10, 25, 30, 85, 75, 60],
        backgroundColor: '#2400FF',
      },
    ],
  }), []);

  return (
    <MainContainer>
      <SideMenu />
      <ContentContainer>
        <BodyWrapper animate={true}>
          <ExploreContainer>
            <ChartContainer>
              <Card style={{ marginRight: '1%' }}>
                <CardBody>
                  <CustomLabel>
                    CagaDEX TVL
                    <br />
                    <span>$1.65T</span>
                    <span>{new Date().toLocaleString()}</span>
                  </CustomLabel>
                  <ChartWrapper>
                    <Line data={MockLineChartData} options={chartOptions} />
                  </ChartWrapper>
                </CardBody>
              </Card>
              <Card style={{ marginLeft: '1%' }}>
                <CardBody>
                  <CustomLabel>
                    CagaDEX Volume
                    <br />
                    <span>$3.11K</span>
                    <span style={{ color: 'grey' }}>per month</span>
                  </CustomLabel>
                  <ChartWrapper>
                    <Bar data={MockBarChartData} options={chartOptions} />
                  </ChartWrapper>
                </CardBody>
              </Card>
            </ChartContainer>
            <NavWrapper>
              <TabsWrapper ref={tabsRef}>
                <Tabs>
                  <StyledNavLink isActive={activeTab === 'tokens'} className={`tab-tokens`} onClick={() => handleTabSelect('tokens')}>
                    Tokens
                  </StyledNavLink>
                  <StyledNavLink isActive={activeTab === 'pools'} className={`tab-pools`} onClick={() => handleTabSelect('pools')}>
                    Pools
                  </StyledNavLink>
                  <StyledNavLink isActive={activeTab === 'transactions'} className={`tab-transactions`} onClick={() => handleTabSelect('transactions')}>
                    Transactions
                  </StyledNavLink>
                </Tabs>
                <Underline />
                <ActiveIndicator left={indicatorStyle.left} width={indicatorStyle.width} />
              </TabsWrapper>
              <FiltersContainer>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggleStyled caret>
                    <img src={cagaLogo} alt="Caga Logo" style={{ width: '20px', marginRight: '10px' }} />
                  </DropdownToggleStyled>
                  <DropdownMenuStyled>
                    <DropdownItem>
                      <img src={cagaLogo} alt="Caga Logo" style={{ width: '20px', marginRight: '10px' }} />
                      Caga
                    </DropdownItem>
                  </DropdownMenuStyled>
                </Dropdown>
                {activeTab === 'tokens' && (
                  <>
                    <Dropdown isOpen={volumeDropdownOpen} toggle={toggleVolumeDropdown}>
                      <DropdownToggleStyled caret>
                        {selectedVolume}
                      </DropdownToggleStyled>
                      <VolumeDropdownMenuStyled>
                        <DropdownItem onClick={() => handleVolumeSelect('1H Volume')}>1H Volume</DropdownItem>
                        <DropdownItem onClick={() => handleVolumeSelect('1D Volume')}>1D Volume</DropdownItem>
                        <DropdownItem onClick={() => handleVolumeSelect('1W Volume')}>1W Volume</DropdownItem>
                        <DropdownItem onClick={() => handleVolumeSelect('1M Volume')}>1M Volume</DropdownItem>
                        <DropdownItem onClick={() => handleVolumeSelect('1Y Volume')}>1Y Volume</DropdownItem>
                      </VolumeDropdownMenuStyled>
                    </Dropdown>
                  </>
                )}
                {(activeTab === 'tokens' || activeTab === 'pools') && (
                  <>
                    <div style={{ marginTop: '5px' }}>
                      <SearchInput
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </>
                )}
              </FiltersContainer>
            </NavWrapper>
            <BottomLayout>
              <LeftLayout>
                {activeTab === 'tokens' && <TokenList volumePeriod={selectedVolume} searchQuery={searchQuery.trim()} />}
                {activeTab === 'pools' && <PoolList searchQuery={searchQuery.trim()} />}
                {activeTab === 'transactions' && <TransactionsList />}
              </LeftLayout>
            </BottomLayout>
          </ExploreContainer>
        </BodyWrapper>
      </ContentContainer>
    </MainContainer>
  );
}