import { useState, useEffect } from 'react';
import { namehash } from 'ethers/lib/utils';
import isZero from '../utils/isZero';
import { isAddress } from '../utils';
import { useENSRegistrarContract, useENSResolverContract } from './useContract';
import useDebounce from './useDebounce';

interface UseENSNameReturn {
  ENSName: string | null;
  loading: boolean;
}

export default function useENSName(address?: string): UseENSNameReturn {
  const debouncedAddress = useDebounce(address, 200);
  const [ENSName, setENSName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [resolverAddress, setResolverAddress] = useState<string | undefined>();
  const registrarContract = useENSRegistrarContract(false);

  useEffect(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) {
      setENSName(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    const ensNode = namehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`);
    registrarContract?.resolver(ensNode).then((addr: string) => {
      setResolverAddress(addr);
      setLoading(false);
    }).catch(() => {
      setENSName(null);
      setLoading(false);
    });
  }, [debouncedAddress, registrarContract]);

  const resolverContract = useENSResolverContract(resolverAddress, false);

  useEffect(() => {
    if (!resolverAddress || isZero(resolverAddress)) {
      setENSName(null);
      return;
    }

    setLoading(true);
    resolverContract?.name(namehash(`${debouncedAddress?.toLowerCase().substr(2)}.addr.reverse`)).then((name: string) => {
      setENSName(name || null);
      setLoading(false);
    }).catch(() => {
      setENSName(null);
      setLoading(false);
    });
  }, [resolverAddress, resolverContract, debouncedAddress]);

  return {
    ENSName,
    loading,
  };
}
