import { useState, useEffect } from 'react';
import { TokenAmount, Pair, Currency } from '@caga-cryptos/cagaswap-v2-sdk';
import IUniswapV2Pair from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { Interface } from '@ethersproject/abi';
import { ethers } from 'ethers';
import { useWeb3ReactContext } from '../contexts/Web3Context';
import { wrappedCurrency } from '../utils/wrappedCurrency';

const IUniswapV2PairABI = IUniswapV2Pair.abi;
// const FACTORY_ADDRESS = "0xd9d3F89076A4dD1a44863fb11B9CB3cc87dB15Bb";

const CTT_ADDRESS = "0x4Bb796B90e3BAFEF741826822Ee85a80B22A8A21";
/*
const FACTORY_ABI = [
  {
    "constant": true,
    "inputs": [
      { "internalType": "address", "name": "tokenA", "type": "address" },
      { "internalType": "address", "name": "tokenB", "type": "address" }
    ],
    "name": "getPair",
    "outputs": [
      { "internalType": "address", "name": "", "type": "address" }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
];
*/
export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}


const PAIR_INTERFACE = new Interface(IUniswapV2PairABI);
//const FACTORY_INTERFACE = new Interface(FACTORY_ABI);

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId, library } = useWeb3ReactContext();
  const [pairs, setPairs] = useState<[PairState, Pair | null][]>(() =>
    currencies.map(() => [PairState.LOADING, null])
  );

  useEffect(() => {
    if (!library || !chainId) {
      setPairs(currencies.map(() => [PairState.NOT_EXISTS, null]));
      return;
    }

    const tokens = currencies.map(([currencyA, currencyB]) => [
      wrappedCurrency(currencyA, chainId),
      wrappedCurrency(currencyB, chainId),
    ]);

    const fetchPairs = async () => {
     // const factoryContract = new ethers.Contract(FACTORY_ADDRESS, FACTORY_INTERFACE, library);

      const pairsData: [PairState, Pair | null][] = await Promise.all(
        tokens.map(async ([tokenA, tokenB]): Promise<[PairState, Pair | null]> => {
          // Condition for using the Factory contract's getPair method
          if (!tokenA || !tokenB || tokenA.equals(tokenB)) {
            return [PairState.INVALID, null];
          }
         

          let pairAddress = ethers.constants.AddressZero;
          try {
 
            pairAddress = Pair.getAddress(tokenA, tokenB);
            if (pairAddress === ethers.constants.AddressZero) {
              throw new Error('Pair not found');
            }


            // Fetch reserves and construct the Pair object
            const pairContract = new ethers.Contract(pairAddress, PAIR_INTERFACE, library);
            const reserves = await pairContract.getReserves();

            
            
            const [reserve0, reserve1] = [reserves.reserve0, reserves.reserve1];
            const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
            const pair = new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()));

            return [PairState.EXISTS, pair];
          } catch (error) {
            return [PairState.NOT_EXISTS, null];
          }
        }));

      setPairs(pairsData);
    };

    fetchPairs();
  }, [chainId, library, JSON.stringify(currencies)]);

  return pairs;
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const result = usePairs([[tokenA, tokenB]]);
  return result[0];
}
