import React, { useCallback, useContext, useState, useEffect, CSSProperties } from 'react';
import { CurrencyAmount } from '@caga-cryptos/cagaswap-v2-sdk';
import { ThemeContext } from 'styled-components';
import { ethers } from 'ethers';
import { useWeb3ReactContext } from '../../contexts/Web3Context';
import { Token } from '@caga-cryptos/cagaswap-v2-sdk';
import AppBody from '../AppBody';
import { MainContainer, ContentContainer } from '../LayoutStyles';
import SideMenu from '../../components/SideMenu';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import AddressInputPanel from '../../components/AddressInputPanel';
import { ButtonPrimary, ButtonLight } from '../../components/Button';
import { AutoColumn } from '../../components/Column';
import { Wrapper, BottomGrouping } from '../../components/Swap/styleds';
import { TYPE } from '../../theme';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { useCurrency } from '../../hooks/Tokens';
import { Field } from '../../state/swap/actions';
import {
  useDerivedSwapInfo,
  useSwapActionHandlers,
} from '../../state/swap/hooks';
import { useWalletModalToggle } from '../../state/application/hooks';
import InformationSection from '../../components/InformationSection';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { useIsDarkMode } from '../../state/user/hooks';
import { logEvent } from '../../ga4'; // Import GA4 event logging

export default function Send() {
  const { account, library } = useWeb3ReactContext();
  const theme = useContext(ThemeContext);
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(useCurrency('ETH')); // Initial currency selection
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isRecipientValid, setIsRecipientValid] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { open } = useWeb3Modal();

  useEffect(() => {
    setIsRecipientValid(ethers.utils.isAddress(recipient));
  }, [recipient]);

  const isCnt = selectedCurrency?.symbol === 'CNT';

  const handleSend = async () => {
    if (!account || !library || !selectedCurrency || !isRecipientValid) {
      setTransactionStatus('Please connect your wallet, select a token, and enter a valid recipient address.');
      return;
    }

    setIsSending(true); // Start loading indicator
    const customGasLimit = ethers.utils.hexlify(100000); // Custom gas limit; adjust as needed
    const signer = library.getSigner();

    try {
      let tx;
      if (selectedCurrency.symbol === 'ETH' || selectedCurrency.symbol === 'CAGA') {
        tx = await signer.sendTransaction({
          to: recipient,
          value: ethers.utils.parseEther(amount),
          gasLimit: customGasLimit,
        });
      } else {
        // Handling ERC-20 token transfer with correct casting to Token type
        const contract = new ethers.Contract(
          (selectedCurrency as Token).address, // Correctly using the address from the Token type
          ["function transfer(address to, uint amount) external returns (bool)"],
          signer
        );
        tx = await contract.transfer(recipient, ethers.utils.parseUnits(amount, selectedCurrency.decimals), {
          gasLimit: customGasLimit,
        });
      }
      await tx.wait();
      // Log successful transaction event
      logEvent('Transaction', 'Send', selectedCurrency.symbol);
      // Displaying transaction status with a link to the explorer
      setTransactionStatus(`Transaction successful: View on explorer <a href="https://explorer.ankara-cagacrypto.com/transaction/${tx.hash}" target="_blank" rel="noopener noreferrer" style="color:${theme.green1};">here</a>.`);
    } catch (error) {
      console.error("Transaction failed: ", error);
      // Log transaction error
      logEvent('Transaction', 'Send Failed', selectedCurrency.symbol);
      setTransactionStatus('Transaction failed. See console for details.');
    } finally {
      setIsSending(false); // Stop loading indicator
    }
  };

  const isDarkMode = useIsDarkMode();
  const borderStyle = isDarkMode ? '2px solid white' : '4px solid white';
  const DownArrowIcon = ({ style, strokeColor }: { style?: CSSProperties, strokeColor?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke={strokeColor || "#C3C5CB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={style}>
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>
  );

  return (
    <>
      <MainContainer>
        <SideMenu />
        <ContentContainer>
          <AppBody>
            <SwapPoolTabs active={'send'} />
            <Wrapper id="send-page">
              <AutoColumn gap={"md"} displayType={"block"}>
                <CurrencyInputPanel
                  label="From"
                  value={amount}
                  onUserInput={setAmount}
                  onCurrencySelect={setSelectedCurrency}
                  onMax={() => { }}
                  showMaxButton={false}
                  currency={selectedCurrency}
                  id="send-currency-input"
                />
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '5px 1rem',
                  position: 'relative',
                }}>
                  <DownArrowIcon
                    strokeColor={theme.text1}
                    style={{
                      zIndex: '99',
                      width: '30px',
                      height: '30px',
                      backgroundColor: theme.fieldBg1,
                      border: borderStyle,
                      borderRadius: '40px',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  />
                </div>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={setRecipient}
                />
              </AutoColumn>
              <BottomGrouping>
                {!account ? (
                  // Show "Connect to Wallet" if no account is connected
                  <ButtonPrimary onClick={() => open()}>
                    Connect Wallet
                  </ButtonPrimary>
                ) : (
                  // Otherwise, show the "Send" button
                  <ButtonPrimary onClick={handleSend} disabled={!isRecipientValid || isSending || isCnt}>
                    {isSending ? "Sending..." : isCnt ? "This token is not supported" : <TYPE.main color="#FFF">Send</TYPE.main>}
                  </ButtonPrimary>

                )}
                {/* Transaction status display remains unchanged */}
                {transactionStatus && (
                  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
                    <TYPE.body dangerouslySetInnerHTML={{ __html: transactionStatus }} />
                  </div>
                )}
              </BottomGrouping>
            </Wrapper>
          </AppBody>
          <InformationSection trade={true} />
        </ContentContainer>
      </MainContainer>
    </>
  );
}
