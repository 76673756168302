// src/globalErrorHandlers.ts

// Global error handler for uncaught exceptions
window.onerror = function (message, source, lineno, colno, error) {
    console.error('Global error caught:', { message, source, lineno, colno, error });
    // Optionally log error to an external service
    return true; // Prevent the error from being further propagated and displayed
  };
  
  // Global error handler for unhandled promise rejections
  window.onunhandledrejection = function (event) {
    console.error('Unhandled rejection:', event.reason);
    // Optionally log error to an external service
    return true; // Prevent the error from being further propagated and displayed
  };
  
  // Add an empty export statement to make this file a module
  export {};
  