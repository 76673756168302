import { Web3Provider } from '@ethersproject/providers';
import { ChainId } from '@caga-cryptos/cagaswap-v2-sdk';
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect'; // Importing isMobile for mobile detection
import { injected } from '../connectors'; // Direct usage as per your requirement
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';

export interface ActiveWeb3ReactContext {
  account?: string;
  library?: Web3Provider;
  chainId?: ChainId;
  active: boolean;
  error?: Error;
  connector?: any;
}

export function useActiveWeb3React(): ActiveWeb3ReactContext {
  const { active, error, chainId, account } = useWeb3ReactCore();
  const { address, chainId: modalChainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [library, setLibrary] = useState<Web3Provider | undefined>(undefined);

  useEffect(() => {
    // Only instantiate a new Web3Provider if there's a walletProvider available and no library exists yet.
    if (walletProvider) {
      // console.log("Creating new Web3Provider for walletProvider");
      const ethersProvider = new Web3Provider(walletProvider);
      setLibrary(ethersProvider);
    }

    // Clean up the library when the component unmounts or when walletProvider changes.
    return () => {
      //console.log("Cleaning up the library");
      setLibrary(undefined);
    };
  }, [walletProvider, modalChainId, address]); // Dependency on just walletProvider to control effect execution

  return {
    account: address ?? "", // Fallback to useWeb3ReactCore account if modal account isn't available
    library,
    chainId: modalChainId || chainId,
    active,
    error,
    connector: walletProvider // Directly use walletProvider as the connector
  };
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized && localStorage.getItem('autoConnect') === 'true') {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      localStorage.setItem('autoConnect', 'true');
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
